import { Convert } from '../helpers/';
import Node from "../../../Classes/Node";
import { getBGProps } from '../../../Helpers/functions';

export const drawGrid = (canvasParams, ImageBGData, refCanvasImageBG, refCanvasMoveImageBGOverlay) => {
    const { ctx, offsetX, offsetY, zoom, canvas, hoverObject } = canvasParams;

    const center = Convert.toPixel(new Node(0, 0), canvasParams);
    const cellSize = Math.ceil(1000 * zoom);
    const cellPosX = (center.x + 0) % cellSize;
    const cellPosY = (center.y + 0) % cellSize;
    canvas.style.backgroundPosition = cellPosX + 'px ' + cellPosY + 'px';
    canvas.style.backgroundSize = cellSize + 'px ' + cellSize + 'px';
    if (ImageBGData.mode !== '' && ImageBGData.points && ImageBGData.isEditing) {
        const pointA = Convert.toPixel(ImageBGData.points[0], canvasParams);
        const pointB = Convert.toPixel(ImageBGData.points[1], canvasParams);
        ctx.lineWidth = 1;
        const r = 30 * zoom;

        if (hoverObject && hoverObject.type === 'ImageBGpointA')
            ctx.strokeStyle = '#ef7534';
        else
            ctx.strokeStyle = '#2e7906';

        ctx.beginPath();
        ctx.arc(pointA.x, pointA.y, r, 0, 2 * Math.PI);
        ctx.stroke();
        ctx.closePath();


        if (hoverObject && hoverObject.type === 'ImageBGpointB')
            ctx.strokeStyle = '#ef7534';
        else
            ctx.strokeStyle = '#2e7906';

        ctx.beginPath();
        ctx.arc(pointB.x, pointB.y, r, 0, 2 * Math.PI);
        ctx.stroke();
        ctx.closePath();

        ctx.strokeStyle = '#2e7906';

        if (ImageBGData.mode === 'line') {
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(pointA.x, pointA.y);
            ctx.lineTo(pointB.x, pointB.y);
            ctx.stroke();
            ctx.closePath();
        } else if (ImageBGData.mode === 'square') {
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(pointA.x, pointA.y);
            ctx.lineTo(pointA.x, pointB.y);
            ctx.lineTo(pointB.x, pointB.y);
            ctx.lineTo(pointB.x, pointA.y);
            ctx.lineTo(pointA.x, pointA.y);
            ctx.closePath();
            ctx.stroke();
        }
    }

    const { width, height, x, y } = getBGProps(canvasParams, ImageBGData);

    const bg_move_width = 30;
    const bg_move_height = 30;

    const bg_move_PosX = x + width / 2 - bg_move_width / 2;
    const bg_move_PosY = y + height / 2 - bg_move_height / 2;

    refCanvasImageBG.current.style.backgroundSize = width + 'px';
    refCanvasImageBG.current.style.backgroundPosition = x + 'px ' + y + 'px';

    refCanvasMoveImageBGOverlay.current.style.backgroundSize = bg_move_width + 'px';
    refCanvasMoveImageBGOverlay.current.style.backgroundPosition = bg_move_PosX + 'px ' + bg_move_PosY + 'px';
}
