import { useSelector } from "react-redux";
import * as THREE from 'three/build/three.min';
import Wnd from "./Wnd";
import Door from "./Door";
import Node from "./Node";
//import store from '../Redux/store';
class Link {
    constructor(node1, node2, type = '', lrBuild = 'left') {
        this.a = node1;
        this.b = node2;
        this.depth = 100; //5~50
        this.innerDepth = 0; //5~50
        this.height = 2700;//150~350
        this.radius = 0;
        this.lrBuild = lrBuild;
        this.controlA = null;
        this.controlB = null;
        this.showCircle = false;
        this.isArc = false;
        this.isBezier = false;
        this.isRuler = type === 'ruler';
        this.isWall = type === 'wall';
        this.isPolygonWall = type === 'polygonWall';
        this.isFigure = type === 'figure';
        this.isLeader = type === 'leader';
        this.arcInverse = false;
        this.showModule = true;
        this.isLink = true;
        this.leaderText = '';

        this.objTitle = '';
        this.objComment = '';
        this.objImages = [];
    }
    equals(l) {
        return (l.a === this.a && l.b === this.b) || (l.a === this.b && l.b === this.a);
    }
    get length() {
        return Math.round(this.a.clone().sub(this.b).length());
    }
    set length(val) {
        if (this.length !== val) {
            const v = this.a.clone().sub(this.b);
            const __v = v.clone().negate().normalize().setLength(val);
            this.b.x = __v.x + this.a.x;
            this.b.y = __v.y + this.a.y;
        }
    }
    isHover(point) {
        return point.distanceToLink(this) <= this.h / 2;
    }
    isExist() {
        return this.isArc || this.isBezier || this.isRuler || this.isWall || this.isPolygonWall || this.isFigure || this.isLeader
    }
};

export default Link;
