import { colors } from './plannerConfig';
import Node from '../../Classes/Node';
import { Convert } from '../PlanEditor/helpers';

export const rgbToHex = (r, g, b) => {
    if (r > 255 || g > 255 || b > 255)
        throw "Invalid color component";
    return ((r << 16) | (g << 8) | b).toString(16);
}

export const getWallByPoint = (canvasParams, pointWindow, onActive = false) => {
    const { plan, filters, ctxFake, ratio, zoom, activeObject } = canvasParams;
    let result = null;
    if (filters.walls) {
        plan.polygonWalls.forEach((wall) => {
            if (activeObject === null || activeObject !== wall || !activeObject.isPolygonWall || onActive) {
                ctxFake.clearRect(0, 0, ctxFake.width, ctxFake.height);
                ctxFake.fillStyle = colors.main.point;
                ctxFake.strokeStyle = colors.main.point;

                ctxFake.beginPath();
                wall.nodes.forEach((n, index) => {
                    const node = Convert.toPixel(n, canvasParams);
                    if (index === 0) {
                        ctxFake.moveTo(node.x, node.y);
                    } else {
                        ctxFake.lineTo(node.x, node.y);
                    }
                    if (index === wall.nodes.length - 1) {
                        ctxFake.closePath();
                        ctxFake.fill();
                    }
                })

                const pixel = ctxFake.getImageData(pointWindow.x * ratio, pointWindow.y * ratio, 1, 1).data
                const hex = "#" + ("000000" + rgbToHex(pixel[0], pixel[1], pixel[2])).slice(-6);

                if (hex === colors.main.point) {
                    result = wall;
                }
            }
        })

        if (result) return result;

        plan.bWalls.map((wall) => {
            if (activeObject === null || !activeObject.isWall || activeObject.mainLink !== wall.mainLink || onActive) {
                ctxFake.clearRect(0, 0, ctxFake.width, ctxFake.height);
                ctxFake.fillStyle = colors.main.point;
                ctxFake.strokeStyle = colors.main.point;
                if (wall.isBezier && wall.outlineBezier) {
                    ctxFake.beginPath();

                    [...wall.inlineBezier].reverse().forEach((bezier, index) => {
                        const a = Convert.toPixel(bezier.points[3], canvasParams);
                        const b = Convert.toPixel(bezier.points[2], canvasParams);
                        const c = Convert.toPixel(bezier.points[1], canvasParams);
                        const d = Convert.toPixel(bezier.points[0], canvasParams);

                        if (index === 0) {
                            ctxFake.moveTo(a.x, a.y);
                        } else {
                            ctxFake.lineTo(a.x, a.y);
                        }

                        ctxFake.bezierCurveTo(b.x, b.y, c.x, c.y, d.x, d.y);
                    });

                    wall.outlineBezier.forEach((bezier) => {
                        const a = Convert.toPixel(bezier.points[0], canvasParams);
                        const b = Convert.toPixel(bezier.points[1], canvasParams);
                        const c = Convert.toPixel(bezier.points[2], canvasParams);
                        const d = Convert.toPixel(bezier.points[3], canvasParams);

                        ctxFake.lineTo(a.x, a.y);
                        ctxFake.bezierCurveTo(b.x, b.y, c.x, c.y, d.x, d.y);
                    })

                    ctxFake.closePath()
                    ctxFake.fill();
                } else {
                    const a = Convert.toPixel(wall.nodes[2], canvasParams);
                    const b = Convert.toPixel(wall.nodes[3], canvasParams);
                    const c = Convert.toPixel(wall.nodes[5], canvasParams);
                    const d = Convert.toPixel(wall.nodes[4], canvasParams);

                    ctxFake.beginPath();
                    ctxFake.moveTo(a.x, a.y);
                    ctxFake.lineTo(b.x, b.y);
                    ctxFake.lineTo(c.x, c.y);
                    ctxFake.lineTo(d.x, d.y);
                    ctxFake.closePath();
                    ctxFake.fill();
                }

                const pixel = ctxFake.getImageData(pointWindow.x * ratio, pointWindow.y * ratio, 1, 1).data
                const hex = "#" + ("000000" + rgbToHex(pixel[0], pixel[1], pixel[2])).slice(-6);

                if (hex === colors.main.point) {
                    result = wall;
                }
            }
        });
    }
    return result;
}

export const getLinkByPoint = (canvasParams, pointWindow) => {
    const { plan, filters, ctxFake, ratio, zoom, activeObject } = canvasParams;
    let result = false;
    plan.links.map(link => {
        if ((filters.ruler && (link.isRuler || link.isLeader)) || (filters.floors && link.isFigure)) {
            if (activeObject !== null
                && (activeObject.isRuler || activeObject.isFigure || activeObject?.isLeader)
                && activeObject === link) {
                // Линк, который уже выделен - пропускаем, чтобы можно было выбрать линк "под ним"
            } else {
                const linkDepth = 100;
                ctxFake.clearRect(0, 0, ctxFake.width, ctxFake.height);
                ctxFake.lineWidth = (linkDepth * 2) * zoom
                ctxFake.fillStyle = colors.main.point;
                ctxFake.strokeStyle = colors.main.point;
                if (link.isArc && link.arcRadius1) {
                    const pivotPoint = Convert.toPixel(link.a, canvasParams);
                    const arcRadius2 = Math.ceil((link.arcRadius + linkDepth / 2) * zoom);

                    ctxFake.lineWidth = (linkDepth + 300) * zoom;
                    ctxFake.beginPath();
                    ctxFake.arc(pivotPoint.x, pivotPoint.y, arcRadius2, link.arcRadius1.angle(), link.arcRadius2.angle());
                    ctxFake.stroke();
                } else {
                    const a = Convert.toPixel(link.a, canvasParams);
                    const b = Convert.toPixel(link.b, canvasParams);

                    ctxFake.beginPath();
                    if (link?.isLeader) {
                        const leaderLength = 1000 * zoom;
                        ctxFake.moveTo(a.x, a.y);
                        ctxFake.lineTo(b.x, b.y);
                        ctxFake.lineTo(b.x + leaderLength, b.y);
                    } else {
                        ctxFake.moveTo(a.x, a.y);
                        ctxFake.lineTo(b.x, b.y);
                    }
                    ctxFake.closePath();
                    ctxFake.stroke();
                }
                function rgbToHex(r, g, b) {
                    if (r > 255 || g > 255 || b > 255)
                        throw "Invalid color component";
                    return ((r << 16) | (g << 8) | b).toString(16);
                }
                const pixel = ctxFake.getImageData(pointWindow.x * ratio, pointWindow.y * ratio, 1, 1).data
                const hex = "#" + ("000000" + rgbToHex(pixel[0], pixel[1], pixel[2])).slice(-6);

                if (hex === colors.main.point) {
                    result = link;
                }
            }
        }

    });
    return result;
}

export const getCycleByPoint = (canvasParams, pointWindow, type) => {
    const { plan, filters, ctxFake, ratio, zoom, activeObject } = canvasParams;
    let result = false;
    if (filters.floors) {
        plan.cycles.map((cycle, index) => {
            if ((type === 'figures' && cycle.isFigure) || (type === 'floors' && cycle.isFloor)) {
                if (activeObject !== null && activeObject.isCycle && activeObject === cycle) {
                } else {
                    ctxFake.clearRect(0, 0, ctxFake.width, ctxFake.height);
                    ctxFake.lineWidth = 1;
                    ctxFake.fillStyle = colors.main.point;
                    ctxFake.strokeStyle = colors.main.point;
                    ctxFake.beginPath();
                    cycle.points.forEach((node, index) => {
                        const point = Convert.toPixel(new Node(node.x, node.y), canvasParams)
                        if (index === 0) {
                            ctxFake.moveTo(point.x, point.y);
                        }

                        const wall = plan.bWalls.filter((w) => w.innerLink.a === node || w.innerLink.b === node)[0];
                        if (wall?.isBezier) {
                            const a = Convert.toPixel(wall.nodes[0], canvasParams);
                            const b = Convert.toPixel(wall.nodes[1], canvasParams);
                            const control_1A = Convert.toPixel(wall.bezierControlPoint_1A, canvasParams);
                            const control_1B = Convert.toPixel(wall.bezierControlPoint_1B, canvasParams);

                            if (b.x === point.x && b.y === point.y) {
                                ctxFake.lineTo(b.x, b.y);
                                ctxFake.bezierCurveTo(control_1B.x, control_1B.y, control_1A.x, control_1A.y, a.x, a.y);
                            } else {
                                ctxFake.lineTo(a.x, a.y);
                                ctxFake.bezierCurveTo(control_1A.x, control_1A.y, control_1B.x, control_1B.y, b.x, b.y);
                            }
                        } else {
                            ctxFake.lineTo(point.x, point.y);
                        }
                    });
                    ctxFake.closePath();
                    ctxFake.stroke();
                    ctxFake.fill();

                    const pixel = ctxFake.getImageData(pointWindow.x * ratio, pointWindow.y * ratio, 1, 1).data
                    const hex = "#" + ("000000" + rgbToHex(pixel[0], pixel[1], pixel[2])).slice(-6);

                    if (hex === colors.main.point) {
                        result = { cycle, index };
                    }
                }
            }
        });
    }
    return result;
}
