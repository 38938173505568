import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as THREE from 'three';
import Plan from '../../../Classes/Plan';
import { actionsState as projectState } from '../../../Redux/project';
import { saveFile } from '../Export/DXF/utils';
import { actionsState as modulesState } from '../../../Redux/modules';
import { loadModule } from '../../../Sagas/dataSagaAsync';

export const RrmHandler = () => {
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);

    const allModules = useSelector((state) => state.modules.allModules);
    const tokenRID = useSelector(store => store.project.saveResult.tokenRID);

    const [plan, modules] = useSelector((state) => ([
        state.project.planeEdit,
        state.project.plan,
        state.modules.modules
    ]));

    // todo: Вынести в отдельный компонент
    const getRRM = () => {
        dispatch(projectState.setEditMode(false));
        dispatch(projectState.addPreloader());

        const objects = modules.map(m => ({
            id: m.id,
            ordinalNumber: m.ordinalNumber,
            position: { x: m.position.x, y: m.position.y },
            size: m.size,
            sizeDef: m.sizeDef,
            heightFromFloor: m.heightFromFloor,
            angle: m.angle,
            corp: m.corp ? m.corp.userData.ID : null,
            face: m.face ? m.face.userData.ID : null,
            cloth: m.cloth ? m.cloth.userData.ID : null,
            option: m.optionStatus,

            objTitle: m.objTitle,
            objComment: m.objComment,
            objImages: m.objImages,
            estimate: m?.estimate || [],

            rgb: m.rgb,
            rgbColor: m.rgbColor,
        }));

        const obj = {
            plan: { ...plan.toOBJ() },
            objects,
        }
        saveFile(JSON.stringify(obj), `${tokenRID}.rrm`).then(() => dispatch(projectState.decPreloader()));
    }

    const uploadRRM = (event) => {
        const file = event.target.files[0];

        if (file) {
            if (file.name.endsWith('.rrm')) {
                const reader = new FileReader();
                reader.onload = async (e) => {
                    const fileContent = e.target.result;
                    const object = JSON.parse(fileContent);

                    const plan = new Plan();
                    plan.loadFromOBJ(object.plan);
                    const { offsetX, offsetY, zoom } = plan.getCommonPlanPosition();
                    plan.offsetX = offsetX;
                    plan.offsetY = offsetY;
                    plan.zoom = zoom;
                    plan.minZoom = 6;
                    plan.maxZoom = Math.min(zoom - 0.01, 0.01);
                    plan.restored = true

                    dispatch(modulesState.clearInstanceModules());
                    dispatch(projectState.setPlan(plan));

                    if (object.objects && object.objects?.length) {
                        for (const mod of object.objects) {
                            const shema = allModules.find(m => m.ID == mod.id);
                            if (!shema) {
                                console.error('_obj ERROR mod.id', mod.id)
                            } else {
                                const m = await loadModule(shema);
                                m.position = new THREE.Vector2(mod.position.x, mod.position.y);
                                m.heightFromFloor = mod.heightFromFloor ? mod.heightFromFloor : 0;
                                m.size = mod.size ? mod.size : false;
                                m.sizeDef = mod.sizeDef ? mod.sizeDef : false;
                                m.angle = mod.angle ? mod.angle : 0;

                                m.model.scale.x = (m.size.sizeX / (m.sizeDef.sizeX / 1000)) / 1000
                                m.model.scale.y = (m.size.sizeY / (m.sizeDef.sizeY / 1000)) / 1000
                                m.model.scale.z = (m.size.sizeZ / (m.sizeDef.sizeZ / 1000)) / 1000

                                m.objTitle = mod.objTitle
                                m.objComment = mod.objComment
                                m.objImages = mod.objImages
                                m.rgbColor = mod.rgbColor;
                                m.rgb = mod.rgb;
                                m.estimate = mod?.estimate || [];

                                if (mod.rgb.b !== false && mod.rgb.g !== false && mod.rgb.r !== false) {
                                    m.model.traverse(property => {
                                        if (property.isMesh) {
                                            property.material.color.r = mod.rgb.r / 255;
                                            property.material.color.g = mod.rgb.g / 255;
                                            property.material.color.b = mod.rgb.b / 255;
                                        }
                                    });
                                }

                                m.optionStatus = mod.option ? mod.option : false;
                                const corp = window.materials.corp.find(m => m.userData.ID === mod.corp);
                                const face = window.materials.face.find(m => m.userData.ID === mod.face);
                                const cloth = window.materials.cloth.find(m => m.userData.ID === mod.cloth);
                                m.corp = corp ? corp.clone() : window.materials.corp[0].clone();
                                m.face = face ? face.clone() : window.materials.face[0].clone();

                                m.cloth = cloth ? cloth.clone() : window.materials.cloth[0].clone();
                                m.size = mod.size ? mod.size : false;
                                m.sizeDef = mod.sizeDef ? mod.sizeDef : false;

                                dispatch(modulesState.addInstanceModule(m));
                            }
                        }
                    }
                };
                reader.readAsText(file);
            }
        }
    }

    return (
        <>
            <li onClick={() => { getRRM(); }}>
                Экспорт проекта (.rrm)
            </li>
            <li onClick={() => { fileInputRef.current.click(); }}>
                <input ref={fileInputRef} onChange={uploadRRM} type="file" accept=".rrm" style={{display: 'none'}}/>
                Импорт проекта (.rrm)
            </li>
        </>
    );
}
