import React, { useEffect, useState } from 'react';
import ModalWrapper from "./ModalWrapper";
import { useDispatch, useSelector } from "react-redux";
import { actionsState as projectState } from "../Redux/project";
import { sendRedrawSimpleEvent, sendUnselectEvent } from "../Helpers/Events";
import SizeBlock from "./widgets/SizeBlock";

const PointInfo = ({ node }) => {
    const dispatch = useDispatch();
    const plan = useSelector((state)=> state.project.plan);
    const planeEdit = useSelector((state)=> state.project.planeEdit);
    const [nY, set_nY] = useState(node.x);
    const [nX, set_nX] = useState(node.y);
    const [isPolygonWall, setIsPolygonWall] = useState(node.isPolygonWall || false);
    const [_isLocked, setLocked] = useState(node.isLocked);

    const canvasSelector = planeEdit ? "#plan" : "#scene";

    const lockNode = ()=>{
        node.isLocked = !node.isLocked
        setLocked(node.isLocked)
    }

    const handlerX = (value) => {
        set_nX(value);
        node.x = value*1;
        plan.moveDragPairNode(node);
        sendRedrawSimpleEvent(document.querySelector('#plan'));
        plan.setActionUndo({type:'plan'});
    }

    const handlerY = (value) => {
        set_nY(value);
        node.y = value*1;
        plan.moveDragPairNode(node);
        sendRedrawSimpleEvent(document.querySelector('#plan'));
        plan.setActionUndo({type:'plan'});
    }

    const close = () => {
        dispatch(projectState.setModal(''));
        if (planeEdit) {
            sendUnselectEvent(document.querySelector('#plan'));
            sendRedrawSimpleEvent(document.querySelector('#plan'));
        }
    }

    const onDeleteNode = () => {
        plan.removeNode(node);
        dispatch(projectState.setModal(""));
        sendUnselectEvent(document.querySelector(canvasSelector));
        sendRedrawSimpleEvent(document.querySelector(canvasSelector));
        plan.setActionUndo({ type: 'plan' });
    };

    useEffect(() => {
        set_nX(node.x);
        set_nY(node.y);
        setLocked(node.isLocked);
        setIsPolygonWall(node.isPolygonWall || false);
    },[plan, node, node.x, node.y, node.isLocked, node.isPolygonWall])

    return (
        <ModalWrapper
            isSideMenu={true}
            onDelete={isPolygonWall ? onDeleteNode : null}
            onClose={close}
            onLock={lockNode}
            isLocked={_isLocked}
            title={'Настройка точки'}
            planeEdit={planeEdit}
            node={node}
        >
        <div className="modal-body">
            <div className="options-title">Координаты</div>
            <div className="size-block">
                <h2>Координата X</h2>
                <SizeBlock
                    value={Math.round(nX)}
                    onChange={handlerX}
                    min={false}
                    max={false}
                    isLocked={_isLocked}
                />
            </div>

            <div className="size-block">
                <h2>Координата Y</h2>
                <SizeBlock
                    value={Math.round(nY)}
                    onChange={handlerY}
                    min={false}
                    max={false}
                    isLocked={_isLocked}
                />
            </div>
        </div>
        </ModalWrapper>
    )
}

export default PointInfo
