import { lengthBezierCurve } from '../../../Helpers/GeometryAlgoritm';
import { Convert } from '../helpers';

export const drawSize = (canvasProps, link, position) => {
    const { ctx, plan, zoom, activeObject } = canvasProps;

    const wall = plan.bWalls.filter(w => w.innerLink === link)[0];
    const wallLength = link.length;
    const sortWallHoles = (a, b, link) => {
        if (Math.round(link.a.x / 100) === Math.round(link.b.x / 100)) {
            if (link.a.y < link.b.y) {
                return a.objB.y - b.objB.y;
            }
            return b.objB.y - a.objB.y;
        } else if (link.a.x < link.b.x) {
            return a.objB.x - b.objB.x
        }
        return b.objB.x - a.objB.x;
    }

    const columns = [];
    const wallColumns = wall?.mainLink?.lrBuild === 'left' ? [...(wall?.rightCols || [])] : [...(wall?.leftCols || [])];
    wallColumns.forEach((columnIndex) => {
        const column = plan.columns?.[columnIndex];

        if (column?.location) {
            columns.push({
                objA: wall.mainLink.lrBuild === 'left' ? column.p3 : column.p4,
                objB: wall.mainLink.lrBuild === 'left' ? column.p4 : column.p3,
                width: column.width,
                len1: wall.mainLink.lrBuild === 'left' ? column.location.len1 : column.location.len2,
                isColumn: true
            });
        }
    });

    // Проверка на арку (потом надо переписать)
    const wallHoles = wall && !wall.isArc && !wall.isBezier ?
        [...wall.objects, ...columns]
            .filter((obj) => obj.isHole || obj.isWindow || obj.isDoor || obj?.isColumn)
            .sort((a, b) => sortWallHoles(a, b, link))
        : [];

    const wallSizes = wallHoles.reduce((sizes, currHole, currentIndex) => {
        const prevHole = wallHoles[currentIndex - 1];

        // Размеры стены слева от проёма
        if (currentIndex === 0) {
            sizes.push({ a: link.a, b: currHole.objA, width: currHole.len1 });
        } else {
            sizes.push({ a: prevHole.objB, b: currHole.objA, width: currHole.len1 - prevHole.len1 - prevHole.width });
        }

        if (!currHole?.isColumn) {
            // Размеры текущего проема
            sizes.push({ a: currHole.objA, b: currHole.objB, width: currHole.width });
        }

        // Размеры стены справа крайнего проёма
        if (currentIndex === wallHoles.length - 1) {
            sizes.push({ a: currHole.objB, b: link.b, width: wallLength - currHole.len1 - currHole.width });
        }

        return sizes;
    }, []);

    if (wallHoles.length === 0) wallSizes[0] = { a: link.a, b: link.b, width: link.length };

    if (wallSizes && wall) wall.wallSizes = wallSizes;

    wallSizes.forEach((hole) => {
        ctx.lineWidth = 1
        ctx.font = '10px Arial';
        ctx.strokeStyle = '#FFB800';
        ctx.fillStyle = '#FFB800';

        if (activeObject !== null && activeObject.isWall && !activeObject.isLink && activeObject.innerLink === link) {
            ctx.strokeStyle = '#59DA28';
            ctx.fillStyle = '#59DA28';
        }

        let length = 0, lengthText = '';

        if (wall && wall.isArc && wall.arcRadius1) {
            length = Math.abs(wall.arcLenght.toFixed(2));
            lengthText = '∩ ' + length;
        } else if (wall && wall.isBezier && wall.bezierControlPoint_1A && wall.bezierControlPoint_1B) {
            const distance = hole.width;
            length = wall.inlineBezier.reduce((acc, cur) => {
                acc += lengthBezierCurve(
                    cur.points[0].x, cur.points[0].y,
                    cur.points[1].x, cur.points[1].y,
                    cur.points[2].x, cur.points[2].y,
                    cur.points[3].x, cur.points[3].y,
                );
                return acc;
            }, 0);
            lengthText = distance + ', ∩ ' + length;
        } else {
            length = hole.width;
            lengthText = hole.width;
        }

        const tw = ctx.measureText(lengthText).width + 8;

        if (length * zoom > tw + 10 && position === 'right') {
            const _a = Convert.toPixel(hole.a, canvasProps);
            const _b = Convert.toPixel(hole.b, canvasProps);
            const v = _b.clone().sub(_a); //вектор стены
            const c = _b.clone().add(_a).multiplyScalar(0.5); //центр в рихелях
            const a = v.angle(); //угол поворота в rad относительно ------------> (x)filll
            const len = v.length();
            let halfH = -12;
            ctx.translate(c.x, c.y);
            ctx.rotate(a);
            ctx.beginPath();
            ctx.moveTo(-len / 2, -halfH - 17);
            ctx.lineTo(-len / 2, -halfH - 23);

            ctx.moveTo(len / 2, -halfH - 17);
            ctx.lineTo(len / 2, -halfH - 23);

            ctx.moveTo(-len / 2, -halfH - 20);
            ctx.lineTo(len / 2, -halfH - 20);
            ctx.stroke();

            halfH = 0;

            ctx.translate(0, -8 - halfH);
            ctx.fillRect(-tw / 2, -8 - 1, tw, 16);
            ctx.strokeRect(-tw / 2, -8 - 1, tw, 16);
            if (a > Math.PI / 2 && a < 3 * Math.PI / 2) ctx.rotate(Math.PI);
            ctx.fillStyle = '#FFFFFF';
            ctx.fillText(lengthText, 0, 0);
            if (a > Math.PI / 2 && a < 3 * Math.PI / 2) ctx.rotate(-Math.PI);
            ctx.translate(0, 8 + halfH);

            ctx.rotate(-a);
            ctx.translate(-c.x, -c.y);
        } else if (length * zoom > tw + 10) {
            const _a = Convert.toPixel(hole.a, canvasProps);
            const _b = Convert.toPixel(hole.b, canvasProps);
            const v = _b.clone().sub(_a); //вектор стены
            const c = _b.clone().add(_a).multiplyScalar(0.5); //центр в рихелях
            const a = v.angle(); //угол поворота в rad относительно ------------> (x)filll
            const len = v.length();
            let halfH = -28;
            ctx.translate(c.x, c.y);
            ctx.rotate(a);
            ctx.beginPath();
            ctx.moveTo(-len / 2, -halfH - 17);
            ctx.lineTo(-len / 2, -halfH - 23);

            ctx.moveTo(len / 2, -halfH - 17);
            ctx.lineTo(len / 2, -halfH - 23);

            ctx.moveTo(-len / 2, -halfH - 20);
            ctx.lineTo(len / 2, -halfH - 20);
            ctx.stroke();

            halfH = 0;

            ctx.translate(0, 8 + halfH);
            ctx.fillRect(-tw / 2, -8 + 1, tw, 16);
            ctx.strokeRect(-tw / 2, -8 + 1, tw, 16);
            if (a > Math.PI / 2 && a < 3 * Math.PI / 2) ctx.rotate(Math.PI);
            ctx.fillStyle = '#FFFFFF';
            ctx.fillText(lengthText, 0, 0);
            if (a > Math.PI / 2 && a < 3 * Math.PI / 2) ctx.rotate(-Math.PI);
            ctx.translate(0, -8 - halfH);

            ctx.rotate(-a);
            ctx.translate(-c.x, -c.y);
        }
    })
}
