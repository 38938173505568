import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Provider } from "react-redux";
import store from './Redux/store'
import { Configurator, History } from './pages';
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { MainErrorComponent } from './Components/Error/MainErrorComponent';
import { ErrorBoundary } from 'react-error-boundary';

window.conf_nofunc = false;
// window.conf_nofunc = true;
// if (window.innerWidth < 1024)
//     window.conf_noTouchFunc = true

var uri = window.location.search.substring(1);
var params = new URLSearchParams(uri);
window.debug = (params.get("debug") == 'Y') ? true : false;
window.speed = (params.get("speed") == 'Y') ? true : false;

if (undefined === window.confAjaxUrl) { // для локальной отладки
    window.confSiteUrl = 'https://testrerooms.a-de.ru';
    //window.confSiteUrl = 'http://rerooms.loc';
    window.confComponentUrl = '/';
    //window.confAjaxUrl = '/local/components/a-de/configurator/templates/.default/ajax/configurator.php';
    window.confAjaxUrl = '/local/components/a-de/configurator/templates/.default/ajax/';
    window.confAjaxUrl2dSchemes = '/local/components/a-de/configurator/templates/.default/ajax/';
}

window.rr_token = params.get("token");
if (params.get("fixedRole")) window.fixedRole = params.get("fixedRole");

// window.debug = false;
// window.debug = true;

document.addEventListener('gesturestart', function (e) {
    e.preventDefault();
    // special hack to prevent zoom-to-tabs gesture in safari
    document.body.style.zoom = 0.99;
});

document.addEventListener('gesturechange', function (e) {
    e.preventDefault();
    // special hack to prevent zoom-to-tabs gesture in safari
    document.body.style.zoom = 0.99;
});

document.addEventListener('gestureend', function (e) {
    e.preventDefault();
    // special hack to prevent zoom-to-tabs gesture in safari
    document.body.style.zoom = 0.99;
});

const router = createBrowserRouter([
    {
        path: "/*",
        element: (
            <ErrorBoundary FallbackComponent={MainErrorComponent}>
                <Configurator />
            </ErrorBoundary>
        ),
        children: [
            {
                path: "history",
                element: <History />,
            },
        ],
    },
]);

const ErrorContext = React.createContext({});

ReactDOM.render(
    <React.StrictMode>
        <ErrorContext.Provider value={null}>
            <Provider store={store}>
                <RouterProvider router={router} />
            </Provider>
        </ErrorContext.Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorkerRegistration.register()
