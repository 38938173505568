import {Vector2} from "three";
class Node extends Vector2{
    constructor(x,y,type = ''){
        super(x,y);
        this.isLocked = false;
        this.isControl = type==='control';
        this.isRuler = type==='ruler';
        this.isWall = type==='wall';
        this.isPolygonWall = type==='polygonWall';
        this.isFigure = type==='figure';
        this.isLeader = type==='leader';
    }
    set(x,y){
        super.set(x,y);
    }
    distanceToLink(l){
        const v = [l.b.x-l.a.x,l.b.y-l.a.y];
        const w = [this.x-l.a.x,this.y-l.a.y];

        const c1 = v[0]*w[0]+v[1]*w[1];
        if(c1<=0) return this.distanceToNode(l.a);

        const c2 = v[0]*v[0]+v[1]*v[1];
        if(c2<=c1) return this.distanceToNode(l.b);

        return Math.abs(((l.a.y - l.b.y)*this.x+(l.b.x-l.a.x)*this.y+(l.a.x*l.b.y-l.b.x*l.a.y))/
            Math.sqrt((l.b.x-l.a.x)*(l.b.x-l.a.x)+(l.b.y-l.a.y)*(l.b.y-l.a.y)));
    }
    distanceToNode(n){
        return Math.sqrt((this.x-n.x)*(this.x-n.x)+(this.y-n.y)*(this.y-n.y))
    }
    isExist() {
        return this.isControl || this.isRuler || this.isWall || this.isPolygonWall || this.isFigure || this.isLeader
    }
}

export default Node;
