import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  sendRedrawSimpleEvent,
  sendUnselectEvent,
} from "../Helpers/Events";
import { actionsState as projectState } from "../Redux/project";
import ModalWrapper from "./ModalWrapper";
import SizeBlock from "./widgets/SizeBlock";

import { DistanceButton } from "./BluetoothRoulette/DistanceButton";
import { distance } from "./BluetoothRoulette/utils/distance";
import { useShortcuts } from "./Features/Shortcuts";
import { EstimateInfo, ObjectList } from './Features/SideMenu';
import MaterialSelect from "./MaterialSelect";
import { Select } from "./UI";

const optionsFillWall = [
  { label: "Бетон", value: "blackFill" },
  { label: "Кирпич", value: "hatchDraw" },
  { label: "Гипсокартон", value: "beigeFill" },
  { label: "Пазоблок", value: "greyFill" },
  { label: "Пеноблок", value: "pointsDraw" },
];

const PolygonWallInfo = ({ plan, wall }) => {
  const dispatch = useDispatch();

  const planeEdit = useSelector((state) => state.project.planeEdit);
  const bluetoothStatus = useSelector(
    (store) => store.project.devices.bluetooth.status
  );
  const canvasSelector = planeEdit ? "#plan" : "#scene";

  const [materialWnd, setMaterialWnd] = useState(false);
  const [material, setMaterial] = useState(wall.material);
  const [materialRGBrgb, setMaterialRGBrgb] = useState(wall.materialRGB.rgb);
  const [materialRGBrgbColor, setMaterialRGBrgbColor] = useState(
    wall.materialRGB.rgbColor
  );

  const [_activeObject, setActiveObject] = useState(null);

  const [distanceButton, setDistanceButton] = useState({
    property: { depth: false, length: true, height: false, innerDepth: false },
  });

  const _setMaterial = (m) => {
    wall.materialRGB.rgbColor = false;
    setMaterialRGBrgbColor(false);

    wall.material = m;
    setMaterial(m);
    sendRedrawSimpleEvent(document.querySelector(canvasSelector));

    plan.setActionUndo({ type: "plan" });
  };
  const _setMaterialRgbColor = (rgb) => {
    wall.materialRGB.rgbColor = true;
    setMaterialRGBrgbColor(true);
    wall.materialRGB.rgb = rgb;
    setMaterialRGBrgb(rgb);

    sendRedrawSimpleEvent(document.querySelector(canvasSelector));

    plan.setActionUndo({ type: "plan" });
  };

  const [visibility, setVisibility] = useState(wall.showModule);
  const toggleVisibility = () => {
    setVisibility(!visibility);
    wall.showModule = !visibility;

    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    plan.setActionUndo({ type: "plan" });
  };

  const [height, setHeight] = useState(wall.height);
  const handlerHeight = (value) => {
    setHeight(value);
    wall.height = value;
  };

  const [planMaterial, setPlanMaterial] = useState(wall.planMaterial);
  const handlerDrawMaterial = (value) => {
    setPlanMaterial(value);
    wall.planMaterial = value;

    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
  };

  const close = () => {
    dispatch(projectState.setModal(""));
    sendUnselectEvent(document.querySelector(canvasSelector));
    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
  };

  const remove = () => {
    console.log(3)
    plan.removePolygonWall(wall);
    dispatch(projectState.setModal(""));
    sendUnselectEvent(document.querySelector(canvasSelector));
    sendRedrawSimpleEvent(document.querySelector(canvasSelector));
    plan.setActionUndo({ type: 'plan' });
  };

  useEffect(() => {
    setVisibility(wall.showModule);
    setPlanMaterial(wall.planMaterial);
    setHeight(wall.height);
  }, [
    wall,
    wall.height,
    wall.showModule,
    wall.planMaterial,
  ]);

  const setStatusDistanceButton = ({ property, action }) => {
    const newDistanceButton = {
      property: {},
      action: {},
    };
    for (let key in distanceButton.property) {
      newDistanceButton.property[key] = property === key ? true : false;
    }
    newDistanceButton.action = action;
    setDistanceButton(newDistanceButton);
  };

  useShortcuts({ onDelete: remove });

  useEffect(() => {
    if (bluetoothStatus !== "connected") {
      return;
    }
    if (Object.keys(distance.setCallBack)) {
      distance.setCallBack(distanceButton.action);
    } else {
      distance.start(distanceButton.action);
    }
    return () => distance.setCallBack({});
  }, [bluetoothStatus, distanceButton]);

  return (
    <ModalWrapper
      isSideMenu={true}
      title={"Настройки объединяющей стены"}
      onDelete={() => remove()}
      onClose={() => close()}
    >
      <div className="modal-body">
        <div className={"size-block"}>
          <div
            className="btn btn-icon btn-onestyle"
            style={{ marginLeft: "5px" }}
            onClick={toggleVisibility}
          >
            {visibility ? "Скрыть" : "Показать"}
          </div>
        </div>
        <div className="size-block">
          <h2>Высота</h2>
          <SizeBlock
            value={height}
            onChange={handlerHeight}
            min={5}
            step={5}
            isLocked={false}
          />
          {bluetoothStatus === "connected" && (
            <DistanceButton
              property={"height"}
              action={handlerHeight}
              distanceButtonProperty={distanceButton.property}
              setStatusDistanceButtonState={setStatusDistanceButton}
            />
          )}
        </div>

        <br />

        <Select
          options={optionsFillWall}
          value={optionsFillWall.find(
            (option) => option.value === wall.planMaterial
          )}
          onChange={(option) => handlerDrawMaterial(option.value)}
        />

        <div className="materials-block">
          <div className="options-title">Материал стены</div>
          <div className="material-select">
            {!wall.materialRGB.rgbColor && (
              <div
                className="material-select__select"
                onClick={() => setMaterialWnd(true)}
                style={{
                  backgroundImage: "url(" + wall.material.userData.PICT + ")",
                  cursor: "pointer",
                }}
              >
                <span>{wall.material.userData.NAME}</span>
              </div>
            )}
            {wall.materialRGB.rgbColor && (
              <div
                className="material-select__select"
                onClick={() => setMaterialWnd(true)}
                style={{
                  background:
                    "rgba(" +
                    wall.materialRGB.rgb.r +
                    "," +
                    wall.materialRGB.rgb.g +
                    "," +
                    wall.materialRGB.rgb.b +
                    "," +
                    wall.materialRGB.rgb.a +
                    ")",
                  cursor: "pointer",
                }}
              >
                <span>RGB цвет</span>
              </div>
            )}
          </div>
          {materialWnd === true && (
            <MaterialSelect
              onClose={() => setMaterialWnd(false)}
              active={wall.material}
              list={window.materials.wall}
              setMaterial={_setMaterial}
              title="Материал стены"
              rgbColor={true}
              _setRgbColor={_setMaterialRgbColor}
              activeRGB={wall.materialRGB.rgbColor}
              colorDef={wall.materialRGB.rgb}
            />
          )}
        </div>

        <EstimateInfo object={wall} />
      </div>
    </ModalWrapper>
  );
};

export default PolygonWallInfo;
