import Hole from "./Hole";
import ObjectOnWall from "./ObjectOnWall";
import LocalStorageParams from './LocalStorageParams';
import { getTypeObjectOnWall } from '../Components/Utils';
import { calculateMaterialAmount, calculateMaterialQuantity } from '../Helpers/estimate';

class Wall {
    constructor() {
        this.showModule = true;

        this.isWall = true;
        this.isBezier = false;

        this.bezier = null;
        this.bezierControlPoint_1A = null;
        this.bezierControlPoint_1B = null;
        this.outlineBezier = [];
        this.inlineBezier = [];

        // todo: Найти и удалить во всем проекте, больше не используются
        this.isArc = false;
        this.arcRadiusLine = 0;
        this.arcRadius = 0;
        this.arcLenght = 0;
        this.arcCenterCorner = 0;
        this.arcRadiusPoints3D_1 = [];
        this.arcRadiusPoints3D_2 = [];
        this.arcRadius1 = 0;
        this.arcRadius2 = 0;

        this.nodes = [];
        this.mainLink = null;
        this.parallelLink = null;
        this.innerLink = null;

        this.connectedNodes = [];

        this.polygons3d = [];
        this.pairsWalls = [];
        this.planMaterial = 'default';
        this.estimate = [];
        this.leftCols = [];
        this.rightCols = [];

        this.material = window.materials.wall[0];
        this.materialRGB = {
            rgbColor: false,
            rgb: {
                r: '255',
                g: '255',
                b: '255',
                a: '1',
            }
        }

        this.objects = [];

        this.objTitle = '';
        this.objComment = '';
        this.objImages = [];
    }
    removeColumns(cid = -1) {
        if (cid === -1) {
            this.leftCols = [];
            this.rightCols = [];
        } else if (cid > -1) {
            this.leftCols = this.leftCols.filter((el) => el !== cid);
            this.rightCols = this.rightCols.filter((el) => el !== cid);
        }
    }

    setColumns(cid, side) {
        /*side = [left|right];*/
        let arr = this[side + "Cols"];

        if (undefined === arr.find((el) => el === cid)) {
            arr.push(cid);
        }

        this[side + "Cols"] = arr;
    }
    canAddObject(type) {

        if (!this.mainLink.isWall)
            return false;

        let obj = new Hole();
        if (obj) {
            let pos = -1
            let start = 0
            let end = this.isBezier ? this.bezier.length() : this.innerLink.length
            let length = end - start
            const needWidth = obj.width + (obj.padding * 2)

            const sortedObject = [...this.objects].sort((a, b) => a.pos - b.pos)

            for (let i = 0; i < sortedObject.length; i++) {
                const _obj = sortedObject[i]
                length = _obj.pos - start
                if (length >= needWidth) {
                    return true
                }
                if (i < sortedObject.length - 1) {
                    length = sortedObject[i + 1].pos - (_obj.pos + _obj.width)
                    if (length >= needWidth) {
                        return true
                    }
                } else {
                    length = end - (_obj.pos + _obj.width)
                    if (length >= needWidth) {
                        return true
                    }
                }
                start = _obj.pos + _obj.width
            }
            if (pos === -1 && length >= needWidth) {
                return true
            }
        }
        return false
    }
    addObject(type) {
        const localStorageParams = new LocalStorageParams();

        if (!this.mainLink.isWall)
            return false;

        let obj = new Hole();
        if (obj) {
            let pos = -1
            let start = 0
            let end = this.isBezier ? this.bezier.length() : this.innerLink.length
            let length = end - start
            const needWidth = obj.width + (obj.padding * 2)

            this.objects.sort((a, b) => a.pos - b.pos)

            for (let i = 0; i < this.objects.length; i++) {
                const _obj = this.objects[i]
                length = _obj.pos - start
                if (length >= needWidth) {
                    pos = start + obj.padding
                    break
                }
                if (i < this.objects.length - 1) {
                    length = this.objects[i + 1].pos - (_obj.pos + _obj.width)
                    if (length >= needWidth) {
                        pos = _obj.pos + _obj.width + obj.padding
                        break
                    }
                } else {
                    length = end - (_obj.pos + _obj.width)
                    if (length >= needWidth) {
                        pos = _obj.pos + _obj.width + obj.padding
                        break
                    }
                }
                start = _obj.pos + _obj.width
            }
            if (pos === -1 && length >= needWidth) {
                pos = start + obj.padding
            }

            obj.pos = pos
            obj.len1 = obj.pos
            obj.len2 = Math.floor(end - obj.width - obj.pos)
            obj.depth = this.mainLink.depth + this.mainLink.innerDepth;

            if (localStorageParams.getParams('isHole')) {
                const { width, height, heightFromFloor } = localStorageParams.getParams('isHole');
                if (width) {
                    if (width <= length) {
                        obj.width = width;
                    } else {
                        obj.width = length;
                    }
                    obj.pos = 0;
                    obj.len1 = 0;
                    obj.len2 = length;
                }
                if (height) {
                    if (height <= this.mainLink.height) {
                        obj.height = height;
                    } else {
                        obj.height = this.mainLink.height;
                    }
                    obj.pos = 0;
                    obj.len1 = 0;
                    obj.len2 = length;
                }
                if (heightFromFloor) obj.heightFromFloor = heightFromFloor;
            }

            this.objects.push(obj);
            this.sortObjects();
            this.setID();
            this.setOrdinalNumber('isHole');

        }
        return this.objects
    }

    addObjectOnWall(type) {
        const localStorageParams = new LocalStorageParams();

        if (!this.mainLink.isWall) return false;

        let obj = new ObjectOnWall(type);
        if (obj) {
            let pos = -1
            let start = 0
            let end = this.isBezier ? this.bezier.length() : this.innerLink.length
            let length = end - start
            const needWidth = obj.width + (obj.padding * 2)

            this.objects.sort((a, b) => a.pos - b.pos)

            for (let i = 0; i < this.objects.length; i++) {
                const _obj = this.objects[i]
                length = _obj.pos - start
                if (length >= needWidth) {
                    pos = start + obj.padding
                    break
                }
                if (i < this.objects.length - 1) {
                    length = this.objects[i + 1].pos - (_obj.pos + _obj.width)
                    if (length >= needWidth) {
                        pos = _obj.pos + _obj.width + obj.padding
                        break
                    }
                } else {
                    length = end - (_obj.pos + _obj.width)
                    if (length >= needWidth) {
                        pos = _obj.pos + _obj.width + obj.padding
                        break
                    }
                }
                start = _obj.pos + _obj.width
            }
            if (pos === -1 && length >= needWidth) {
                pos = start + obj.padding
            }

            obj.pos = pos;
            obj.len1 = obj.pos;
            obj.len2 = Math.floor(end - obj.width - obj.pos);
            obj.depth = this.mainLink.depth + this.mainLink.innerDepth;
            obj.ordinalNumber = obj.length;
            obj.lrBuild = this.mainLink.lrBuild;

            if (localStorageParams.getParams(getTypeObjectOnWall(obj))) {
                const { width, height, heightFromFloor } = localStorageParams.getParams(getTypeObjectOnWall(obj));
                if (width) obj.width = width;
                if (height) obj.height = height;
                if (heightFromFloor) obj.heightFromFloor = heightFromFloor;
            }

            this.objects.push(obj)
            this.sortObjects();
            this.setID();
            this.setOrdinalNumber('isElectricSocket');

        }
        return this.objects
    }

    removeObject(id) {
        if (!this.mainLink.isWall) {
            return false;
        }
        let filter;
        if (this.objects[id].isHole === true) {
            filter = 'isHole';
        }
        if (this.objects[id].isDoor === true) {
            filter = 'isDoor';
        }
        if (this.objects[id].isWindow === true) {
            filter = 'isWindow';
        }
        if (this.objects[id].isElectricSocket === true) {
            filter = 'isElectricSocket';
        }
        if (this.objects[id].isSwitch === true) {
            filter = 'isSwitch';
        }
        if (this.objects[id].isHeatingBattery === true) {
            filter = 'isHeatingBattery';
        }
        if (this.objects[id].isElectricPanel === true) {
            filter = 'isElectricPanel';
        }
        if (this.objects[id].isRedCube === true) {
            filter = 'isRedCube';
        }
        if (this.objects[id].isCylinder === true) {
            filter = 'isCylinder';
        }
        this.objects = this.objects.filter((obj, index) => index !== id);
        this.setID();
        this.setOrdinalNumber(filter);
    }

    setOrdinalNumber(filter) {
        this.objects.filter(object => object[filter] === true).forEach((object, i) => {
            object.ordinalNumber = i + 1;
        })
    }
    setID() {
        this.objects.forEach((object, i) => {
            object.id = i;
        })
    }
    setEstimate(estimate) {
        this.estimate = estimate.filter((est, index, self) => (
            index === self.findIndex((selfJob) => selfJob.id === est.id)
        )).map((estimate) => {
            return {
                id: estimate.id,
                externalId: estimate.externalId,
                group: estimate.group,
                stage: estimate.stage,
                price: estimate.price,
                code: estimate.code,
                name: estimate.name,
                cost: estimate.cost,
                unit: estimate.unit,
                object: estimate.object,
                volume: estimate.volume,
                amount: estimate.amount,
                materials: estimate.materials.map((material) => {
                    material.volume = estimate.volume;
                    material.quantity = calculateMaterialQuantity(estimate, material);
                    material.amount = calculateMaterialAmount(material);
                    return { ...material }
                })
            }
        });
    }
    sortObjects() {
        const holesWallObjects = this.objects.filter((object) => object.isHole !== undefined);
        const onWallObjects = this.objects.filter((object) => object.isHole === undefined);
        this.objects = [...onWallObjects, ...holesWallObjects];
    }
}

export default Wall;
