import { Convert } from '../helpers/';
import { Vector2 } from "three";
import { findPointOnCurve } from "../../../Helpers/GeometryAlgoritm";
import { colors } from '../../Utils';
import { getIntersectionLongLines } from '../../../Helpers/functions';

const drawCurvedObject = (obj, canvasParams, showEstimated) => {
    const { ctx } = canvasParams;

    if (obj.isWindow) {
        const bezierIndent = obj.bezierIndent;
        const bezierLedge = obj.bezierLedge;

        const A1Inner = bezierIndent[0].points[0];
        const B1Inner = bezierLedge[0].points[0];
        const A2Inner = bezierIndent[bezierIndent.length - 1].points[3];
        const B2Inner = bezierLedge[bezierLedge.length - 1].points[3];

        const bezier_A1Inner = Convert.toPixel(A1Inner, canvasParams);
        const bezier_B1Inner = Convert.toPixel(B1Inner, canvasParams);
        const bezier_A2Inner = Convert.toPixel(A2Inner, canvasParams);
        const bezier_B2Inner = Convert.toPixel(B2Inner, canvasParams);

        ctx.moveTo(bezier_A1Inner.x, bezier_A1Inner.y);
        ctx.lineTo(bezier_B1Inner.x, bezier_B1Inner.y);
        ctx.lineTo(bezier_B2Inner.x, bezier_B2Inner.y);
        ctx.lineTo(bezier_A2Inner.x, bezier_A2Inner.y);
        ctx.lineTo(bezier_A1Inner.x, bezier_A1Inner.y);
    } else {
        obj.bezierIn.forEach((bezier) => {
            const { points: pointsIn } = bezier;

            const bezier_1AIN = Convert.toPixel(pointsIn[0], canvasParams);
            const bezier_2BIN = Convert.toPixel(pointsIn[1], canvasParams);
            const bezier_3CIN = Convert.toPixel(pointsIn[2], canvasParams);
            const bezier_4DIN = Convert.toPixel(pointsIn[3], canvasParams);

            ctx.moveTo(bezier_1AIN.x, bezier_1AIN.y);

            obj.bezierOut.forEach((bezier) => {
                const { points: pointsOut } = bezier;

                const bezier_1AOUT = Convert.toPixel(pointsOut[0], canvasParams);
                const bezier_2BOUT = Convert.toPixel(pointsOut[1], canvasParams);
                const bezier_3COUT = Convert.toPixel(pointsOut[2], canvasParams);
                const bezier_4DOUT = Convert.toPixel(pointsOut[3], canvasParams);

                ctx.lineTo(bezier_1AOUT.x, bezier_1AOUT.y);
                ctx.bezierCurveTo(bezier_2BOUT.x, bezier_2BOUT.y, bezier_3COUT.x, bezier_3COUT.y, bezier_4DOUT.x, bezier_4DOUT.y);
            })

            ctx.lineTo(bezier_4DIN.x, bezier_4DIN.y)
            ctx.bezierCurveTo(bezier_3CIN.x, bezier_3CIN.y, bezier_2BIN.x, bezier_2BIN.y, bezier_1AIN.x, bezier_1AIN.y);
        })
    }
}

const objLength = (obj) => {
    if (obj.isElectricSocket || obj.outletElectricalWire || obj.isSwitch) {
        return obj.type === 'horizontally' ? obj.width * obj.count : obj.width;
    } else {
        return obj.width;
    }
}

const getSumObjectParams = (parent, obj) => {
    const sumObjectParams = obj.vector?.x + obj.vector?.y
        + (obj?.depth || 0)
        + (obj?.depthIndent || 0)
        + (obj?.count || 0);

    if (parent?.isWall) {
        const changedObjParams = obj?.isCorner ? 1 : 0;
        const connectedObject = obj?.connected ? 1 : 0;
        const isConnectedObj = obj?.isConnected ? 1 : 0;
        const isHiddenObj = obj?.isHidden ? 1 : 0;

        const sumParentParams = parent.parallelLink.a.x + parent.parallelLink.b.x
            + parent.parallelLink.a.y + parent.parallelLink.b.y
            + (parent?.mainLink?.controlA?.x || 0)
            + (parent?.mainLink.controlA?.y || 0)
            + (parent?.mainLink?.controlB?.x || 0)
            + (parent?.mainLink?.controlA?.y || 0)
            + changedObjParams
            + connectedObject
            + isConnectedObj
            + isHiddenObj;

        return sumObjectParams + sumParentParams;
    }

    const sumParentParams = parent?.x + parent?.y + parent?.width + parent?.depth + obj?.width;

    return sumObjectParams + sumParentParams;
}

export const drawWallObjects = (canvasParams, parent, showEstimated) => {
    const { ctx, filters, activeObject, infos, zoom, offsetX, offsetY } = canvasParams;

    const isLeftSide = parent?.isWall ? parent.mainLink.lrBuild === 'left' : true;
    const rotate = isLeftSide ? 90 : -90;

    let a = new Vector2(0, 0);
    let b = new Vector2(0, 0);
    let v = new Vector2(0, 0);

    if (parent?.isWall) {
        a = parent.innerLink.a;
        b = parent.innerLink.b;
        v = a.clone().sub(b);
    }

    for (let objIndex = 0; objIndex < parent.objects.length; objIndex++) {
        const obj = parent.objects[objIndex];

        const parentDepth = parent?.isWall ? (parent.mainLink.depth + parent.mainLink.innerDepth) : obj.depth;

        if (parent?.isColumn) {
            switch (obj.side) {
                case 'top': {
                    a = parent.p2;
                    b = parent.p1;
                    break;
                }
                case 'right': {
                    a = parent.p3;
                    b = parent.p2;
                    break;
                }
                case 'bottom': {
                    a = parent.p4;
                    b = parent.p3;
                    break;
                }
                case 'left': {
                    a = parent.p1;
                    b = parent.p4;
                    break;
                }
            }
            v = a.clone().sub(b);
        }

        const onWallObject = obj.isElectricSocket
            || obj.isSwitch
            || obj.isHeatingBattery
            || obj.isElectricPanel
            || obj.isRedCube
            || obj.outletElectricalWire
            || obj.isCylinder;

        const depth = onWallObject ? parentDepth : obj.depth;
        const depthIndent = onWallObject ? 0 : obj.depthIndent;
        let scaleFactor = obj.scale;

        let parentA = { a: parent?.innerLink?.a, b: parent?.parallelLink?.a };
        let parentB = { a: parent?.innerLink?.b, b: parent?.parallelLink?.b };

        let parentInnerA = parentA;
        let parentInnerB = parentB;

        if (obj?.cornerWall) {
            const { cornerWall } = obj;
            const cornerObject = cornerWall?.objects?.find((object) => {
                if (object.isCorner) {
                    if (obj?.cornerWall?.mainLink?.lrBuild === parent?.mainLink?.lrBuild) {
                        return (obj?.len1 === 0 && object?.len2 === 0) || (obj?.len2 === 0 && object?.len1 === 0);
                    } else {
                        return (obj?.len1 === object?.len1) || (obj?.len2 === object?.len2);
                    }
                }
                return false;
            });
            if (!cornerObject) {
                parentA = { a: obj.cornerWall?.parallelLink?.a, b: obj.cornerWall?.parallelLink?.b };
                parentB = { a: obj.cornerWall?.parallelLink?.a, b: obj.cornerWall?.parallelLink?.b };
                parentInnerA = parentA;
                parentInnerB = parentB;
            } else {
                parentInnerA = { a: new Vector2(cornerObject?.rA1Inner?.x, cornerObject?.rA1Inner?.y), b: new Vector2(cornerObject?.rB1Inner?.x, cornerObject?.rB1Inner?.y) };
                parentInnerB = { a: new Vector2(cornerObject?.rA2Inner?.x, cornerObject?.rA2Inner?.y), b: new Vector2(cornerObject?.rB2Inner?.x, cornerObject?.rB2Inner?.y) };
            }
        }

        let objA = a.clone().add(v.clone().setLength(obj.pos).negate());
        let objB = objA.clone().add(v.clone().setLength(objLength(obj)).negate());
        let objV = objA.clone().sub(objB);

        const connectedObject = obj?.isConnected ? obj.connected : null;

        const renderResult = objA?.x + objA?.y + objB?.x + objB?.y + getSumObjectParams(parent, obj);
        const isSceneChanged = (renderResult && !obj?.isCorner) ? obj.lastRenderResult !== renderResult : true;
        obj.lastRenderResult = renderResult;

        if (obj.isHidden) return;

        if (isSceneChanged) {
            if (parent?.isBezier) {
                const bezier = parent.bezier;

                const a = obj.len1;
                const b = obj.len1 + objLength(obj);

                const coordinatesA = findPointOnCurve(bezier, a);
                const coordinatesB = findPointOnCurve(bezier, b);

                const inSide = parent.mainLink.lrBuild === 'left' ? parent.mainLink.innerDepth : -parent.mainLink.innerDepth;
                const outSide = parent.mainLink.lrBuild === 'left' ? -parent.mainLink.depth : parent.mainLink.depth;
                const objBezier = bezier.split(coordinatesA.t, coordinatesB.t);
                obj.bezierIn = objBezier.offset(inSide);
                obj.bezierOut = objBezier.offset(outSide);

                const indent = parent.mainLink.lrBuild === 'left' ? -obj.depthIndent + parent.mainLink.innerDepth : obj.depthIndent - parent.mainLink.innerDepth;
                obj.bezierIndent = objBezier.offset(indent);

                const ledge = parent.mainLink.lrBuild === 'left' ? -obj.depthIndent - parent.mainLink.depth : obj.depthIndent + parent.mainLink.depth;
                obj.bezierLedge = objBezier.offset(ledge);

                objA = new Vector2(obj.bezierIn[0].points[0].x, obj.bezierIn[0].points[0].y);
                objB = new Vector2(obj.bezierIn[obj.bezierIn.length - 1].points[3].x, obj.bezierIn[obj.bezierIn.length - 1].points[3].y);
                objV = objA.clone().sub(objB);
            }

            const __v3d = objV.clone().rotateAround(new Vector2(0, 0), rotate * (Math.PI / 180)).setLength(parentDepth);

            obj.rA13d = { x: objA.x, y: objA.y };
            obj.rB13d = { x: objB.x, y: objB.y };

            if (connectedObject) {
                obj.rA23d = connectedObject.isReverse ? connectedObject.object?.rA13d : connectedObject.object?.rB13d;
                obj.rB23d = connectedObject.isReverse ? connectedObject.object?.rB13d : connectedObject.object?.rA13d;
            } else {
                obj.rA23d = { x: __v3d.x + objA.x, y: __v3d.y + objA.y };
                obj.rB23d = { x: __v3d.x + objB.x, y: __v3d.y + objB.y };
            }

            const __v1 = objV.clone().rotateAround(new Vector2(0, 0), rotate * (Math.PI / 180)).setLength(depthIndent);
            const __v2 = objV.clone().rotateAround(new Vector2(0, 0), rotate * (Math.PI / 180)).setLength(depth + depthIndent);
            obj.rA1Inner = { x: __v1.x + objA.x, y: __v1.y + objA.y };
            obj.rB1Inner = { x: __v1.x + objB.x, y: __v1.y + objB.y };

            if (connectedObject) {
                obj.rA2Inner = connectedObject.isReverse ? connectedObject.object?.rA1Inner : connectedObject.object?.rB1Inner;
                obj.rB2Inner = connectedObject.isReverse ? connectedObject.object?.rB1Inner : connectedObject.object?.rA1Inner;
            } else {
                obj.rA2Inner = { x: __v2.x + objA.x, y: __v2.y + objA.y };
                obj.rB2Inner = { x: __v2.x + objB.x, y: __v2.y + objB.y };
            }

            const __v3dD = objV.clone().rotateAround(new Vector2(0, 0), rotate * (Math.PI / 180)).setLength(obj.depth / 2 + obj.depthIndent);
            obj.DepthPos3d = { x: __v3dD.x + a.x, y: __v3dD.y + a.y }

            if (parent?.isBezier) {
                const bezierA2 = obj.bezierOut[0]?.points[0];
                const bezierB2 = obj.bezierOut[obj.bezierOut.length - 1]?.points[3];

                obj.rA23d = { x: bezierA2?.x, y: bezierA2?.y };
                obj.rB23d = { x: bezierB2?.x, y: bezierB2?.y };

                const bezierA1Inner = obj.bezierIndent[0]?.points[0];
                const bezierB1Inner = obj.bezierIndent[obj.bezierLedge.length - 1]?.points[3];
                const bezierA2Inner = obj.bezierLedge[0]?.points[0];
                const bezierB2Inner = obj.bezierLedge[obj.bezierIndent.length - 1]?.points[3];

                obj.rA1Inner = { x: bezierA1Inner?.x, y: bezierA1Inner?.y };
                obj.rB1Inner = { x: bezierB1Inner?.x, y: bezierB1Inner?.y };
                obj.rA2Inner = { x: bezierA2Inner?.x, y: bezierA2Inner?.y };
                obj.rB2Inner = { x: bezierB2Inner?.x, y: bezierB2Inner?.y };

                obj.DepthPos3d = { x: __v3dD.x + objA.x, y: __v3dD.y + objA.y }
            }

            obj.vector = objV;
            obj.objA = objA;
            obj.objB = objB;

            if (obj.isWindow && obj?.isCorner) {
                if (obj.len1 === 0) {
                    obj.rA1Inner = getIntersectionLongLines({
                        a: new Vector2(obj.rA1Inner.x, obj.rA1Inner.y),
                        b: new Vector2(obj.rB1Inner.x, obj.rB1Inner.y)
                    }, parentInnerA) || obj.rA1Inner;
                    obj.rA2Inner = getIntersectionLongLines({
                        a: new Vector2(obj.rA2Inner.x, obj.rA2Inner.y),
                        b: new Vector2(obj.rB2Inner.x, obj.rB2Inner.y)
                    }, parentInnerB) || obj.rA2Inner;
                }

                if (obj.len2 === 0) {
                    obj.rB1Inner = getIntersectionLongLines({
                        a: new Vector2(obj.rA1Inner.x, obj.rA1Inner.y),
                        b: new Vector2(obj.rB1Inner.x, obj.rB1Inner.y)
                    }, parentInnerA) || obj.rB1Inner;
                    obj.rB2Inner = getIntersectionLongLines({
                        a: new Vector2(obj.rA2Inner.x, obj.rA2Inner.y),
                        b: new Vector2(obj.rB2Inner.x, obj.rB2Inner.y)
                    }, parentInnerB) || obj.rB2Inner;
                }

                if (obj.len1 === 0) {
                    obj.rA13d = getIntersectionLongLines({
                        a: new Vector2(obj.rA13d.x, obj.rA13d.y),
                        b: new Vector2(obj.rB13d.x, obj.rB13d.y)
                    }, parentA) || obj.rA13d;
                    obj.rA23d = getIntersectionLongLines({
                        a: new Vector2(obj.rA23d.x, obj.rA23d.y),
                        b: new Vector2(obj.rB23d.x, obj.rB23d.y)
                    }, parentA) || obj.rA23d;
                }

                if (obj.len2 === 0) {
                    obj.rB13d = getIntersectionLongLines({
                        a: new Vector2(obj.rA13d.x, obj.rA13d.y),
                        b: new Vector2(obj.rB13d.x, obj.rB13d.y)
                    }, parentB) || obj.rB13d;
                    obj.rB23d = getIntersectionLongLines({
                        a: new Vector2(obj.rA23d.x, obj.rA23d.y),
                        b: new Vector2(obj.rB23d.x, obj.rB23d.y)
                    }, parentB) || obj.rB23d;
                }
            }
        }

        if (obj.objTitle !== '' || obj.objComment !== '' || obj.objImages.length > 0) {
            const pos = { x: obj.rA1Inner.x + (obj.rB23d.x - obj.rA1Inner.x) / 2, y: obj.rA1Inner.y + (obj.rB23d.y - obj.rA1Inner.y) / 2 }
            const point = Convert.toPixel(pos, canvasParams);
            infos.push({
                type: 'obj',
                x: point.x,
                y: point.y,
            })
        }

        let grA1Def_pixel = Convert.toPixel(obj.rA13d, canvasParams);
        let grA2Def_pixel = Convert.toPixel(obj.rA23d, canvasParams);
        let grB1Def_pixel = Convert.toPixel(obj.rB13d, canvasParams);
        let grB2Def_pixel = Convert.toPixel(obj.rB23d, canvasParams);

        obj.points = [obj.rA13d, obj.rA23d, obj.rB23d, obj.rB13d];

        ctx.lineWidth = 1;
        ctx.fillStyle = colors.main.objects;
        if (showEstimated && obj.estimate && obj.estimate.length === 0) {
            ctx.fillStyle = colors.estimated.objects;
        }

        ctx.strokeStyle = '#000';

        let grA1Inner_pixel = Convert.toPixel(obj.rA1Inner, canvasParams);
        let grB1Inner_pixel = Convert.toPixel(obj.rB1Inner, canvasParams);
        let grA2Inner_pixel = Convert.toPixel(obj.rA2Inner, canvasParams);
        let grB2Inner_pixel = Convert.toPixel(obj.rB2Inner, canvasParams);

        if (obj.isWindow || obj.isDoor) {
            let a = Convert.toPixel(obj.rA13d, canvasParams);
            let b = Convert.toPixel(obj.rA23d, canvasParams);
            let c = Convert.toPixel(obj.rB13d, canvasParams);
            let d = Convert.toPixel(obj.rB23d, canvasParams);

            if (obj?.isCorner) {
                if (obj.len1 === 0) b = Convert.toPixel(parent.parallelLink.a, canvasParams);
                if (obj.len2 === 0) d = Convert.toPixel(parent.parallelLink.b, canvasParams);
            }

            if (obj.isWindow || obj.isDoor) {
                ctx.fillStyle = '#FFF';
                ctx.beginPath();
                ctx.moveTo(a.x, a.y);
                ctx.lineTo(grA1Inner_pixel.x, grA1Inner_pixel.y);
                ctx.lineTo(grA2Inner_pixel.x, grA2Inner_pixel.y);
                ctx.lineTo(b.x, b.y);
                ctx.lineTo(d.x, d.y);
                ctx.lineTo(grB2Inner_pixel.x, grB2Inner_pixel.y);
                ctx.lineTo(grB1Inner_pixel.x, grB1Inner_pixel.y);
                ctx.lineTo(c.x, c.y);
                ctx.closePath();
                ctx.fill();
                if (obj.isDoor) {
                    ctx.stroke();
                } else {
                    ctx.beginPath();
                    ctx.moveTo(b.x, b.y);
                    ctx.lineTo(d.x, d.y);
                    ctx.closePath();
                    ctx.stroke();

                    ctx.beginPath();
                    ctx.moveTo(a.x, a.y);
                    ctx.lineTo(c.x, c.y);
                    ctx.closePath();
                    ctx.stroke();
                }
            }
        }

        if (
            activeObject !== null
            && (activeObject?.wall === parent || activeObject?.column === parent)
            && activeObject.obj === objIndex
        ) {
            scaleFactor = 1;

            ctx.lineWidth = 20;
            ctx.strokeStyle = 'rgba(255,51,0,0.58)';
            ctx.beginPath();
            ctx.moveTo(grA1Def_pixel.x, grA1Def_pixel.y);
            ctx.lineTo(grA1Inner_pixel.x, grA1Inner_pixel.y);
            ctx.lineTo(grA2Inner_pixel.x, grA2Inner_pixel.y);
            ctx.lineTo(grA2Def_pixel.x, grA2Def_pixel.y);
            ctx.stroke();

            ctx.lineWidth = 20;
            ctx.strokeStyle = 'rgba(0,114,255,0.58)';
            ctx.beginPath();
            ctx.moveTo(grB1Def_pixel.x, grB1Def_pixel.y);
            ctx.lineTo(grB1Inner_pixel.x, grB1Inner_pixel.y);
            ctx.lineTo(grB2Inner_pixel.x, grB2Inner_pixel.y);
            ctx.lineTo(grB2Def_pixel.x, grB2Def_pixel.y);
            ctx.stroke();

            ctx.lineWidth = 1;
            ctx.strokeStyle = '#3ea516';

            if (obj.isWindow || obj.isDoor) {
                ctx.fillStyle = '#66ffed';
                ctx.beginPath();
                ctx.moveTo(grA2Inner_pixel.x, grA2Inner_pixel.y);
                ctx.lineTo(grA2Def_pixel.x, grA2Def_pixel.y);
                ctx.lineTo(grB2Def_pixel.x, grB2Def_pixel.y);
                ctx.lineTo(grB2Inner_pixel.x, grB2Inner_pixel.y);
                ctx.lineTo(grA2Inner_pixel.x, grA2Inner_pixel.y);
                ctx.closePath();
                ctx.fill();
                ctx.stroke();

                ctx.fillStyle = '#c169ff';
                ctx.beginPath();
                ctx.moveTo(grA1Inner_pixel.x, grA1Inner_pixel.y);
                ctx.lineTo(grA1Def_pixel.x, grA1Def_pixel.y);
                ctx.lineTo(grB1Def_pixel.x, grB1Def_pixel.y);
                ctx.lineTo(grB1Inner_pixel.x, grB1Inner_pixel.y);
                ctx.lineTo(grA1Inner_pixel.x, grA1Inner_pixel.y);
                ctx.closePath();
                ctx.fill();
                ctx.stroke();
            }

            ctx.fillStyle = '#59DA28';
        }

        // Проем/ниша
        if (obj.isHole || obj.isWindow) {
            grA1Def_pixel = Convert.toPixel(obj.rA1Inner, canvasParams);
            grA2Def_pixel = Convert.toPixel(obj.rB1Inner, canvasParams);
            grB1Def_pixel = Convert.toPixel(obj.rA2Inner, canvasParams);
            grB2Def_pixel = Convert.toPixel(obj.rB2Inner, canvasParams);

            if (obj.isWindow) ctx.lineWidth = 3;

            ctx.beginPath();

            if (parent?.isBezier) {
                drawCurvedObject(obj, canvasParams, showEstimated);
            } else {
                ctx.moveTo(grA1Def_pixel.x, grA1Def_pixel.y);
                ctx.lineTo(grA2Def_pixel.x, grA2Def_pixel.y);
                ctx.lineTo(grB2Def_pixel.x, grB2Def_pixel.y);
                ctx.lineTo(grB1Def_pixel.x, grB1Def_pixel.y);
                ctx.moveTo(grA1Def_pixel.x, grA1Def_pixel.y);
            }

            ctx.closePath();
            ctx.stroke();
            ctx.fill();

            if (obj.isHole) {
                ctx.beginPath();
                ctx.moveTo(grA1Def_pixel.x, grA1Def_pixel.y);
                ctx.lineTo(grB2Def_pixel.x, grB2Def_pixel.y);
                ctx.stroke();

                ctx.beginPath();
                ctx.moveTo(grA2Def_pixel.x, grA2Def_pixel.y);
                ctx.lineTo(grB1Def_pixel.x, grB1Def_pixel.y);
                ctx.stroke();
            }

            ctx.lineWidth = 1;
        }

        // Дверь
        if (obj.isDoor) {
            let openDoorShift = 0;
            if (isLeftSide) {
                if (obj.inside)
                    openDoorShift = depth;
            } else {
                if (!obj.inside)
                    openDoorShift = depth;
            }

            const __vD = obj.vector.clone().rotateAround(new Vector2(0, 0), 90 * (Math.PI / 180)).setLength(obj.width + openDoorShift + depthIndent)
            obj.dA1 = { x: __vD.x + obj.objA.x, y: __vD.y + obj.objA.y }
            obj.dA2 = { x: __vD.x + obj.objB.x, y: __vD.y + obj.objB.y }

            const __vD2 = obj.vector.clone().rotateAround(new Vector2(0, 0), -90 * (Math.PI / 180)).setLength(obj.width + openDoorShift - depthIndent)
            obj.dB1 = { x: __vD2.x + obj.objA.x, y: __vD2.y + obj.objA.y }
            obj.dB2 = { x: __vD2.x + obj.objB.x, y: __vD2.y + obj.objB.y }

            const dA1_pixel = Convert.toPixel(obj.dA1, canvasParams)
            const dA2_pixel = Convert.toPixel(obj.dA2, canvasParams)
            const dB1_pixel = Convert.toPixel(obj.dB1, canvasParams)
            const dB2_pixel = Convert.toPixel(obj.dB2, canvasParams)

            // ctx.strokeStyle = '#868786'

            if (obj.left) {
                if (obj.inside) {
                    ctx.beginPath()
                    ctx.moveTo(grA1Def_pixel.x, grA1Def_pixel.y)
                    ctx.lineTo(dA1_pixel.x, dA1_pixel.y)
                    ctx.arcTo(dA2_pixel.x, dA2_pixel.y, grB1Def_pixel.x, grB1Def_pixel.y, obj.width * zoom);
                    ctx.stroke()
                } else {
                    ctx.beginPath()
                    ctx.moveTo(grA2Def_pixel.x, grA2Def_pixel.y)
                    ctx.lineTo(dB1_pixel.x, dB1_pixel.y)
                    ctx.arcTo(dB2_pixel.x, dB2_pixel.y, grB2Def_pixel.x, grB2Def_pixel.y, obj.width * zoom);
                    ctx.stroke()
                }
            } else {
                if (obj.inside) {
                    ctx.beginPath()
                    ctx.moveTo(grB1Def_pixel.x, grB1Def_pixel.y)
                    ctx.lineTo(dA2_pixel.x, dA2_pixel.y)
                    ctx.arcTo(dA1_pixel.x, dA1_pixel.y, grA1Def_pixel.x, grA1Def_pixel.y, obj.width * zoom);
                    ctx.stroke()
                } else {
                    ctx.beginPath()
                    ctx.moveTo(grB2Def_pixel.x, grB2Def_pixel.y)
                    ctx.lineTo(dB2_pixel.x, dB2_pixel.y)
                    ctx.arcTo(dB1_pixel.x, dB1_pixel.y, grA2Def_pixel.x, grA2Def_pixel.y, obj.width * zoom);
                    ctx.stroke()
                }
            }

            grA1Def_pixel = Convert.toPixel(obj.rA1Inner, canvasParams);
            grA2Def_pixel = Convert.toPixel(obj.rB1Inner, canvasParams);
            grB1Def_pixel = Convert.toPixel(obj.rA2Inner, canvasParams);
            grB2Def_pixel = Convert.toPixel(obj.rB2Inner, canvasParams);

            ctx.beginPath();

            if (parent?.isBezier) {
                drawCurvedObject(obj, canvasParams, showEstimated);
            } else {
                ctx.moveTo(grA1Def_pixel.x, grA1Def_pixel.y);
                ctx.lineTo(grA2Def_pixel.x, grA2Def_pixel.y);
                ctx.lineTo(grB2Def_pixel.x, grB2Def_pixel.y);
                ctx.lineTo(grB1Def_pixel.x, grB1Def_pixel.y);
                ctx.lineTo(grA1Def_pixel.x, grA1Def_pixel.y);
            }

            ctx.closePath();
            ctx.stroke();
            ctx.fill();

        }

        // Окно
        if (obj.isWindow) {
            ctx.beginPath();

            if (parent?.isBezier) {
                drawCurvedObject(obj, canvasParams, showEstimated);
            }

            ctx.closePath();
            ctx.stroke();
            ctx.fill()

            const __v3 = obj.vector.clone().rotateAround(new Vector2(0, 0), rotate * (Math.PI / 180)).setLength(depthIndent + depth * .3333);
            obj.rA11 = { x: __v3.x + obj.objA.x, y: __v3.y + obj.objA.y };
            obj.rB11 = { x: __v3.x + obj.objB.x, y: __v3.y + obj.objB.y };

            const __v4 = obj.vector.clone().rotateAround(new Vector2(0, 0), rotate * (Math.PI / 180)).setLength((depth + depthIndent) - depth * .3333);
            obj.rA22 = { x: __v4.x + obj.objA.x, y: __v4.y + obj.objA.y };
            obj.rB22 = { x: __v4.x + obj.objB.x, y: __v4.y + obj.objB.y };

            if (obj?.isCorner) {
                if (obj.len1 === 0) {
                    obj.rA11 = getIntersectionLongLines({
                        a: new Vector2(obj.rA11.x, obj.rA11.y),
                        b: new Vector2(obj.rB11.x, obj.rB11.y)
                    }, {
                        a: new Vector2(obj.rA1Inner.x, obj.rA1Inner.y),
                        b: new Vector2(obj.rA2Inner.x, obj.rA2Inner.y)
                    }) || obj.rA11;
                    obj.rA22 = getIntersectionLongLines({
                        a: new Vector2(obj.rA22.x, obj.rA22.y),
                        b: new Vector2(obj.rB22.x, obj.rB22.y)
                    }, {
                        a: new Vector2(obj.rA1Inner.x, obj.rA1Inner.y),
                        b: new Vector2(obj.rA2Inner.x, obj.rA2Inner.y)
                    }) || obj.rA22;
                }

                if (obj.len2 === 0) {
                    obj.rB11 = getIntersectionLongLines({
                        a: new Vector2(obj.rA11.x, obj.rA11.y),
                        b: new Vector2(obj.rB11.x, obj.rB11.y)
                    }, {
                        a: new Vector2(obj.rB1Inner.x, obj.rB1Inner.y),
                        b: new Vector2(obj.rB2Inner.x, obj.rB2Inner.y)
                    }) || obj.rB11;
                    obj.rB22 = getIntersectionLongLines({
                        a: new Vector2(obj.rA22.x, obj.rA22.y),
                        b: new Vector2(obj.rB22.x, obj.rB22.y)
                    }, {
                        a: new Vector2(obj.rB1Inner.x, obj.rB1Inner.y),
                        b: new Vector2(obj.rB2Inner.x, obj.rB2Inner.y)
                    }) || obj.rB22;
                }
            }

            let grA11_pixel = Convert.toPixel(obj.rA11, canvasParams);
            let grA22_pixel = Convert.toPixel(obj.rA22, canvasParams);
            let grB11_pixel = Convert.toPixel(obj.rB11, canvasParams);
            let grB22_pixel = Convert.toPixel(obj.rB22, canvasParams);

            ctx.beginPath();
            ctx.moveTo(grA11_pixel.x, grA11_pixel.y);
            ctx.lineTo(grB11_pixel.x, grB11_pixel.y);
            ctx.closePath();
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(grA22_pixel.x, grA22_pixel.y);
            ctx.lineTo(grB22_pixel.x, grB22_pixel.y);
            ctx.closePath();
            ctx.stroke();
        };

        // ! Рисуем ObjectOnWall ↓ ↓ ↓
        if (onWallObject) {
            grA1Def_pixel = Convert.toPixel(obj.rA1Inner, canvasParams);
            grA2Def_pixel = Convert.toPixel(obj.rB1Inner, canvasParams);
            grB1Def_pixel = Convert.toPixel(obj.rA2Inner, canvasParams);
            grB2Def_pixel = Convert.toPixel(obj.rB2Inner, canvasParams);

            const isActiveObject = (activeObject?.wall === parent || activeObject?.column === parent) && activeObject?.obj === obj.obj;
            const isActiveParent = activeObject === parent;
            const isShowEstimate = showEstimated && obj.estimate && obj.estimate.length === 0;

            if (filters.wallObjects || isActiveObject || isActiveParent || isShowEstimate) {
                ctx.beginPath();

                if (parent?.isBezier) {
                    drawCurvedObject(obj, canvasParams, showEstimated);
                } else {
                    ctx.moveTo(grA1Def_pixel.x, grA1Def_pixel.y);
                    ctx.lineTo(grA2Def_pixel.x, grA2Def_pixel.y);
                    ctx.lineTo(grB2Def_pixel.x, grB2Def_pixel.y);
                    ctx.lineTo(grB1Def_pixel.x, grB1Def_pixel.y);
                    ctx.lineTo(grA1Def_pixel.x, grA1Def_pixel.y);
                }

                ctx.moveTo(grB1Def_pixel.x, grB1Def_pixel.y);
                ctx.stroke();
                ctx.fill();
            }

            const a1 = new Vector2(grA1Def_pixel.x, grA1Def_pixel.y);//yellow
            const a2 = new Vector2(grA2Def_pixel.x, grA2Def_pixel.y);//yellow

            const b1 = new Vector2(grB1Def_pixel.x, grB1Def_pixel.y);//green
            const b2 = new Vector2(grB2Def_pixel.x, grB2Def_pixel.y);//green

            const icon = {};
            icon.center = a1.clone().lerp(a2, 0.5);
            icon.height = a1.distanceTo(a2);
            icon.angle = a1.clone().sub(a2).angle();
            icon.width = a1.distanceTo(a2);

            icon.offsetPendicularCoordinates = (vector, offset) => {
                return {
                    x: vector.x + Math.cos(icon.angle + Math.PI / 2) * offset,
                    y: vector.y + Math.sin(icon.angle + Math.PI / 2) * offset
                }
            };
            icon.mainVectors = {
                vector1: a1.clone(),
                vector2: a2.clone(),
            }

            if (a1.distanceTo(a2) > a1.distanceTo(b1)) {
                icon.height = a1.distanceTo(b1);
            }

            if (!isLeftSide) {
                icon.center = b1.clone().lerp(b2, 0.5);
                icon.mainVectors = {
                    vector1: b1.clone(),
                    vector2: b2.clone(),
                }
            }

            icon.lowerLeftCorner = icon.center.clone().lerp(icon.mainVectors.vector1,
                ((icon.height / 2) / icon.center.distanceTo(icon.mainVectors.vector1)));
            icon.halfLowerLeftCorner = icon.center.clone().lerp(icon.lowerLeftCorner, 0.5);
            icon.lowerRightCorner = icon.center.clone().lerp(icon.mainVectors.vector2,
                ((icon.height / 2) / icon.center.distanceTo(icon.mainVectors.vector2)));
            icon.halfLowerRightCorner = icon.center.clone().lerp(icon.lowerRightCorner, 0.5);
            icon.oneFourth = {
                lowerLeftCorner: icon.mainVectors.vector1.clone().lerp(icon.center, 0.5),
                lowerRightCorner: icon.center.clone().lerp(icon.mainVectors.vector2, 0.5)
            };

            ctx.lineWidth = 1;
            ctx.strokeStyle = '#000';

            ctx.save();

            // * Рисуем ElectricSocket ↓ ↓ ↓
            if (obj.isElectricSocket) {
                if (a1.distanceTo(a2) > a1.distanceTo(b1)) {
                    icon.height = a1.distanceTo(a2);
                }

                icon.height = icon.height * scaleFactor;

                if (obj.lrBuild === "right") {
                    ctx.translate(
                        icon.offsetPendicularCoordinates(icon.center, (a1.distanceTo(b1) + icon.height / 2)).x,
                        icon.offsetPendicularCoordinates(icon.center, (a1.distanceTo(b1) + icon.height / 2)).y
                    );
                    ctx.rotate(icon.angle);

                } else {
                    ctx.translate(
                        icon.offsetPendicularCoordinates(icon.center, (-icon.height / 2)).x,
                        icon.offsetPendicularCoordinates(icon.center, (-icon.height / 2)).y
                    );
                    ctx.rotate(icon.angle + Math.PI);
                }

                // Количество розеток
                const fontSize = 98 * zoom;
                ctx.font = `${fontSize}px sans-serif`;
                ctx.fillStyle = "#000";
                ctx.fillText(obj.count.toString(), 0, (-icon.height / 2) - a1.distanceTo(b1) / 2);

                const isHorizontally = obj.type === 'horizontally';

                const iconSize = isHorizontally ? icon.height / obj.count / 2 : icon.height / 2;
                ctx.beginPath();
                ctx.translate(-icon.height / 2 + iconSize, -icon.height / 2);

                for (let i = 0; i < obj.count; i++) {
                    ctx.arc(0,
                        0,
                        iconSize,
                        0, Math.PI
                    );
                    ctx.moveTo(-iconSize, iconSize);
                    ctx.lineTo(iconSize, iconSize);
                    ctx.moveTo(0, 0);
                    ctx.lineTo(0, iconSize * 3);
                    ctx.moveTo(-iconSize, 0);
                    ctx.lineTo(iconSize, 0);
                    ctx.stroke();
                    isHorizontally ? ctx.translate(iconSize * 2, 0) : i = obj.count;
                }
            }
            // * Рисуем Switch ↓ ↓ ↓
            if (obj.isSwitch) {
                if (a1.distanceTo(a2) > a1.distanceTo(b1)) {
                    icon.height = a1.distanceTo(a2);
                }

                icon.height = icon.height * scaleFactor;

                let offset = obj.lrBuild === "right" ? a1.distanceTo(b1) + icon.height / 4 : -icon.height / 4;
                const offsetX = icon.offsetPendicularCoordinates(icon.center, offset).x;
                const offsetY = icon.offsetPendicularCoordinates(icon.center, offset).y;
                const angle = icon.angle - Math.PI / 4 + (obj.lrBuild === "right" ? - Math.PI / 2 : Math.PI / 2);

                if (obj.count) {
                    ctx.save();
                    ctx.translate(offsetX, offsetY);
                    ctx.rotate(angle);
                    ctx.fillStyle = '#000';
                    ctx.font = `${98 * zoom}px sans-serif`;
                    ctx.rotate(-Math.PI * 5 / 4)
                    ctx.fillText(obj.count.toString(), 0, (-icon.height / 4) - a1.distanceTo(b1) / 2);
                    ctx.restore();
                }

                const isHorizontally = obj.type === 'horizontally';
                const iconSize = (isHorizontally ? (icon.height / obj.count) : icon.height) / 4;

                if (isHorizontally) {
                    offset = obj.lrBuild === "right" ? a1.distanceTo(b1) + iconSize : -iconSize;
                    ctx.translate(
                        icon.offsetPendicularCoordinates(icon.center, offset).x,
                        icon.offsetPendicularCoordinates(icon.center, offset).y
                    );
                    ctx.rotate(angle);
                    ctx.translate(-(-a1.distanceTo(a2) * scaleFactor / 2 + iconSize * 4), (-a1.distanceTo(a2) * scaleFactor / 2 + iconSize * 4));
                } else {
                    ctx.translate(offsetX, offsetY);
                    ctx.rotate(angle);
                }
                for (let i = 0; i < obj.count; i++) {
                    ctx.beginPath();
                    ctx.arc(0, 0, iconSize, 0, 2 * Math.PI);
                    ctx.moveTo(0, (-iconSize));
                    ctx.lineTo(0, -iconSize * 3);
                    ctx.moveTo(-iconSize, (-iconSize * 3));
                    ctx.lineTo(iconSize, (-iconSize * 3));
                    ctx.stroke();
                    ctx.closePath();
                    isHorizontally ? ctx.translate(-iconSize * 3, iconSize * 3) : i = obj.count
                }
            }
            // * Рисуем вывод электропровода ↓ ↓ ↓
            if (obj.outletElectricalWire) {
                if (a1.distanceTo(a2) > a1.distanceTo(b1)) {
                    icon.height = a1.distanceTo(a2);
                }

                icon.height = icon.height * scaleFactor;

                ctx.save();
                ctx.fillStyle = '#000';

                if (obj.lrBuild === "right") {
                    ctx.translate(
                        icon.offsetPendicularCoordinates(icon.center, (a1.distanceTo(b1) + icon.height / 2)).x,
                        icon.offsetPendicularCoordinates(icon.center, (a1.distanceTo(b1) + icon.height / 2)).y
                    );
                    ctx.rotate(icon.angle + Math.PI * 5 / 4);
                } else {
                    ctx.translate(
                        icon.offsetPendicularCoordinates(icon.center, (-icon.height / 2)).x,
                        icon.offsetPendicularCoordinates(icon.center, (-icon.height / 2)).y
                    );
                    ctx.rotate(icon.angle + Math.PI / 4);
                }

                ctx.save();

                if (obj.count) {
                    const fontSize = 98 * zoom;
                    ctx.font = `${fontSize}px sans-serif`;
                    ctx.rotate(-Math.PI * 5 / 4)
                    ctx.fillText(obj.count.toString(), 0, (-icon.height / 2) - a1.distanceTo(b1) / 2);
                }

                ctx.restore();

                const isHorizontally = obj.type === 'horizontally';
                const iconSize = isHorizontally ? icon.height / obj.count : icon.height;

                ctx.beginPath();
                if (isHorizontally) ctx.translate((icon.height - iconSize) * 0.7, 0);

                for (let i = 0; i < obj.count; i++) {
                    ctx.moveTo(0, iconSize * 0.7);
                    ctx.lineTo(0, -iconSize);
                    ctx.stroke();
                    ctx.beginPath();
                    ctx.moveTo(0, -iconSize);
                    ctx.lineTo(-iconSize / 2, -iconSize / 5);
                    ctx.lineTo(iconSize / 2, -iconSize / 5);
                    ctx.fill();
                    isHorizontally ? ctx.translate(-iconSize * 0.7, iconSize * 0.7) : i = obj.count;
                }
            }
            // * Рисуем HeatingBattery ↓ ↓ ↓
            if (obj.isHeatingBattery) {
                icon.width = icon.width * scaleFactor;

                const scale = icon.width / obj.width; // масштаб для рисования
                if (obj.lrBuild === "right") {
                    ctx.save();
                    ctx.translate(icon.offsetPendicularCoordinates(icon.center, (a1.distanceTo(b1) + 0)).x,
                        icon.offsetPendicularCoordinates(icon.center, (a1.distanceTo(b1) + 0)).y);
                    ctx.rotate(icon.angle);
                    ctx.translate(0,
                        obj.depthIndentFor3D * scale);
                } else {
                    ctx.save();
                    ctx.translate(icon.offsetPendicularCoordinates(icon.center, (0)).x,
                        icon.offsetPendicularCoordinates(icon.center, (0)).y);
                    ctx.rotate(icon.angle);
                    ctx.translate(0,
                        -(obj.depthIndentFor3D + obj.depthFor3D) * scale);
                }
                ctx.beginPath();
                ctx.moveTo((icon.width / 4), obj.depthFor3D * scale / 2);
                ctx.lineTo((-icon.width / 4), obj.depthFor3D * scale / 2);
                ctx.moveTo((icon.width / 2), (obj.depthFor3D * scale));
                ctx.strokeRect(-(icon.width / 2), 0, icon.width, (obj.depthFor3D * scale));
                ctx.stroke();
            }
            // * Рисуем ElectricPanel ↓ ↓ ↓
            if (obj.isElectricPanel) {
                const prevFillStyle = ctx.fillStyle;
                const prevStrokeStyle = ctx.strokeStyle;
                const prevLineWidth = ctx.lineWidth;

                ctx.fillStyle = `rgba(255, 255, 255, 1)`;
                ctx.strokeStyle = `rgba(${obj.rgb.r},${obj.rgb.g}, ${obj.rgb.b},${obj.rgb.a})`;

                icon.width = icon.width * scaleFactor;

                const scale = icon.width / obj.width; // масштаб для рисования
                ctx.lineWidth = obj.depthFor3D / 2 * scale;
                if (obj.lrBuild === "right") {
                    ctx.save();
                    ctx.translate(icon.offsetPendicularCoordinates(icon.center, (a1.distanceTo(b1) + 0)).x,
                        icon.offsetPendicularCoordinates(icon.center, (a1.distanceTo(b1) + 0)).y);
                    ctx.rotate(icon.angle);
                    ctx.translate(0,
                        obj.depthIndentFor3D * scale / scaleFactor);

                    ctx.beginPath();
                    ctx.fillRect(-(icon.width / 2), 0, icon.width, (obj.depthFor3D * scale));

                    ctx.beginPath();
                    ctx.moveTo(-(icon.width / 2) - 5 * scale, obj.depthFor3D / 4 * scale);
                    ctx.lineTo((icon.width / 2) + 5 * scale, obj.depthFor3D / 4 * scale);
                    ctx.stroke();

                    ctx.lineWidth = 10 * scale;
                    ctx.beginPath();
                    ctx.moveTo((icon.width / 2), 0);
                    ctx.lineTo((icon.width / 2), obj.depthFor3D * scale);
                    ctx.lineTo(-(icon.width / 2), obj.depthFor3D * scale);
                    ctx.lineTo(-(icon.width / 2), 0);
                    ctx.stroke();
                } else {
                    ctx.save();
                    ctx.translate(icon.offsetPendicularCoordinates(icon.center, (0)).x,
                        icon.offsetPendicularCoordinates(icon.center, (0)).y);
                    ctx.rotate(icon.angle);
                    ctx.translate(0,
                        -(obj.depthIndentFor3D + obj.depthFor3D) * scale / scaleFactor);

                    ctx.beginPath();
                    ctx.fillRect(-(icon.width / 2), 0, icon.width, (obj.depthFor3D * scale));

                    ctx.beginPath();
                    ctx.moveTo(-(icon.width / 2) - 5 * scale, (obj.depthFor3D - obj.depthFor3D / 4) * scale);
                    ctx.lineTo((icon.width / 2) + 5 * scale, (obj.depthFor3D - obj.depthFor3D / 4) * scale);
                    ctx.stroke();

                    ctx.lineWidth = 10 * scale;
                    ctx.beginPath();
                    ctx.moveTo((icon.width / 2), obj.depthFor3D * scale);
                    ctx.lineTo((icon.width / 2), 0);
                    ctx.lineTo(-(icon.width / 2), 0);
                    ctx.lineTo(-(icon.width / 2), obj.depthFor3D * scale);
                    ctx.stroke();
                }

                ctx.fillStyle = prevFillStyle;
                ctx.strokeStyle = prevStrokeStyle;
                ctx.lineWidth = prevLineWidth;
            }
            // * Рисуем redCube ↓ ↓ ↓
            if (obj.isRedCube) {
                const prevFillStyle = ctx.fillStyle;
                ctx.fillStyle = `rgba(${obj.rgb.r},${obj.rgb.g}, ${obj.rgb.b},${obj.rgb.a})`;

                icon.width = icon.width * scaleFactor;

                const scale = icon.width / obj.width; // масштаб для рисования
                if (obj.lrBuild === "right") {
                    ctx.save();
                    ctx.translate(icon.offsetPendicularCoordinates(icon.center, (a1.distanceTo(b1) + 0)).x,
                        icon.offsetPendicularCoordinates(icon.center, (a1.distanceTo(b1) + 0)).y);
                    ctx.rotate(icon.angle);
                    ctx.translate(0,
                        obj.depthIndentFor3D * scale / scaleFactor);
                } else {
                    ctx.save();
                    ctx.translate(icon.offsetPendicularCoordinates(icon.center, (0)).x,
                        icon.offsetPendicularCoordinates(icon.center, (0)).y);
                    ctx.rotate(icon.angle);
                    ctx.translate(0,
                        -(obj.depthIndentFor3D + obj.depthFor3D) * scale / scaleFactor);
                }
                ctx.beginPath();
                ctx.fillRect(-(icon.width / 2), 0, icon.width, (obj.depthFor3D * scale));
                ctx.fillStyle = prevFillStyle;
            }

            if (obj.isCylinder) {
                const prevFillStyle = ctx.fillStyle;
                ctx.fillStyle = `rgba(${obj.rgb.r},${obj.rgb.g}, ${obj.rgb.b},${obj.rgb.a})`;

                icon.width = icon.width * scaleFactor;

                const scale = icon.width / obj.width;
                let offsetX, offsetY;

                if (obj.lrBuild === "right") {
                    offsetX = icon.offsetPendicularCoordinates(icon.center, a1.distanceTo(b1)).x;
                    offsetY = icon.offsetPendicularCoordinates(icon.center, a1.distanceTo(b1)).y;
                } else {
                    offsetX = icon.offsetPendicularCoordinates(icon.center, 0).x;
                    offsetY = icon.offsetPendicularCoordinates(icon.center, 0).y;
                }

                ctx.save();
                ctx.translate(offsetX, offsetY);
                ctx.rotate(icon.angle);
                ctx.translate(0, obj.lrBuild === "right" ? obj.depthIndentFor3D * scale / scaleFactor : -obj.depthIndentFor3D * scale / scaleFactor);

                ctx.beginPath();
                ctx.arc(0, obj.lrBuild === "right" ? icon.width / 2 : -icon.width / 2, icon.width / 2, 0, Math.PI * 2);
                ctx.fill();

                ctx.fillStyle = prevFillStyle;
                ctx.restore();
            }

            ctx.restore();
        }

    }
}
