import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  sendRedrawEvent,
  sendRedrawSimpleEvent,
  sendSelectObjectEvent,
  sendUnselectEvent,
} from "../Helpers/Events";
import { actionsState as projectState } from "../Redux/project";
import { getTypeObjectOnWall } from "./Utils";

import ModalWrapper from "./ModalWrapper";
import ObjectInfoBlock from "./widgets/ObjectInfoBlock";
import SizeBlock from "./widgets/SizeBlock";
import ColorRBG from "./widgets/ColorRBG";

import LocalStorageParams from "../Classes/LocalStorageParams";

import { DistanceButton } from "./BluetoothRoulette/DistanceButton";
import { distance } from "./BluetoothRoulette/utils/distance";
import { useShortcuts } from "./Features/Shortcuts";
import { EstimateInfo } from "./Features/SideMenu";
import { Select } from "./UI";

const optionsObjectsCount = [
  { label: "Одинарный", value: "default1" },
  { label: "Горизонтальный 2", value: "horizontally2" },
  { label: "Горизонтальный 3", value: "horizontally3" },
  { label: "Горизонтальный 4", value: "horizontally4" },
  { label: "Горизонтальный 5", value: "horizontally5" },
  { label: "Вертикальный 2", value: "vertically2" },
  { label: "Вертикальный 3", value: "vertically3" },
  { label: "Вертикальный 4", value: "vertically4" },
  { label: "Вертикальный 5", value: "vertically5" },
];

const WallObjectInfo = ({ parent, objIndex }) => {
  const dispatch = useDispatch();

  const localStorageParams = new LocalStorageParams();

  const plan = useSelector((state) => state.project.plan);
  const bluetoothStatus = useSelector((store) => store.project.devices.bluetooth.status);
  const planeEdit = useSelector((state) => state.project.planeEdit);
  const canvasSelector = planeEdit ? "#plan" : "#scene";

  const [distanceButton, setDistanceButton] = useState({
    property: {
      width: true,
      height: false,
      depth: false,
      heightFromFloor: false,
      depthIndent: false,
      depthLedge: false,
      Len1: false,
      Len2: false,
      depth3D: false,
      depthIndentFor3D: false,
    },
    action: handlerWidth,
  });

  const object = parent.objects[objIndex];
  if (undefined === object.id) {
    let wCnt = 0;
    let dCnt = 0;
    parent.objects.forEach((o) => {
      if (o.isWindow) {
        wCnt++;
        o.id = wCnt;
      } else if (o.isDoor) {
        dCnt++;
        o.id = dCnt;
      }
    });
  }

  const [time, setTime] = useState(Date.now());
  const [type, setType] = useState(object.type);
  const [width, setWidth] = useState(object.width);
  const [height, setHeight] = useState(object.height);
  const [heightFromFloor, setHeightFromFloor] = useState(object.heightFromFloor);
  const [pos, setPos] = useState(object.pos);
  const [scale, setScale] = useState(object.scale);
  const [len1, setLen1] = useState(object.len1);
  const [len2, setLen2] = useState(object.len2);
  const [depth, setDepth] = useState(object.depth);
  const [depth3D, setDepthFor3D] = useState(object.depthFor3D);
  const [depthIndent, setDepthIndent] = useState(object.depthIndent);
  const [depthIndentFor3D, setDepthIndentFor3D] = useState(object.depthIndent);
  const [rgb, setRgb] = useState(object.rgb ? object.rgb : { r: 255, g: 0, b: 0, a: 1 });

  const [isHole, setIsHole] = useState(object.isHole);
  const [isDoor, setIsDoor] = useState(object.isDoor);
  const [isWindow, setIsWindow] = useState(object.isWindow);
  const [objectOnWall, setSateObjectOnWall] = useState({
    isElectricSocket: object.isElectricSocket,
    isSwitch: object.isSwitch,
    isHeatingBattery: object.isHeatingBattery,
    isElectricPanel: object.isElectricPanel,
    isRedCube: object.isRedCube,
    isCylinder: object.isCylinder,
    outletElectricalWire: object.outletElectricalWire,
  });


  const [inside, setInside] = useState(object.inside);
  const [lrBuild, setLrBuild] = useState(object.lrBuild);

  const [left, setLeft] = useState(object.left);
  const [isCircleHole, setIsCircleHole] = useState(object?.isCircleHole);
  const [isCorner, setIsCorner] = useState(object.isCorner);

  const getParentLength = useCallback((object, parent) => {
    let length;
    if (parent?.isWall) {
      length = parent.isBezier ? parent.bezier.length() : parent.innerLink.length
    } else {
      length = object.side === 'top' || object.side === 'bottom' ? parent.width : parent.depth
    }
    return length;
  }, [object, parent]);

  const maxLength = useMemo(() => {
    return Math.floor(getParentLength(object, parent) - object.width);
  }, [object.width, parent]);

  const { title, objName, isAperture, isObjectOnWall } = useMemo(() => {
    const isAperture = object.isWindow || object.isDoor || object.isHole;
    const isObjectOnWall =
        object.isElectricSocket ||
        object.isSwitch ||
        object.isHeatingBattery ||
        object.isElectricPanel ||
        object.isRedCube ||
        object.outletElectricalWire ||
        object.isCylinder;

    let title = "";
    let objName = "";

    if (isAperture) {
      if (object.isWindow) {
        title = "Настройки окна";
        objName = "Окно " + object.ordinalNumber;
      }
      if (object.isDoor) {
        title = "Настройки двери";
        objName = "Дверь " + object.ordinalNumber;
      }
      if (object.isHole) {
        title = "Настройки проема";
        objName = "Проем " + object.ordinalNumber;
      }
    }

    if (isObjectOnWall) {
      if (object.isElectricSocket) {
        title = "Настройки розетки";
        objName = "Розетка " + object.ordinalNumber;
      }
      if (object.isSwitch) {
        title = "Настройки выключателя";
        objName = "Выключатель " + object.ordinalNumber;
      }
      if (object.outletElectricalWire) {
        title = "Настройка вывода электропровода";
        objName = "Вывод электропровода" + object.ordinalNumber;
      }
      if (object.isHeatingBattery) {
        title = "Настройки батареи отопления";
        objName = "Батарея отопления " + object.ordinalNumber;
      }
      if (object.isElectricPanel) {
        title = "Настройки электрощита";
        objName = "Электрощит " + object.ordinalNumber;
      }
      if (object.isRedCube) {
        title = "Настройки куба";
        objName = "Куб " + object.ordinalNumber;
      }
      if (object.isCylinder) {
        title = "Настройки цилиндра";
        objName = "Цилиндр " + object.ordinalNumber;
      }
    }

    return { title, objName, isObjectOnWall, isAperture };
  }, [object]);

  const endHandler = () => {
    setTime(Date.now());
    sendRedrawEvent(document.querySelector("#plan"));
    plan.setActionUndo({ type: "plan" });
  };

  function handlerWidth(value) {
    if (value < 0) return;

    setWidth(value);

    const length = getParentLength(object, parent);

    localStorageParams.updateParam(
      getTypeObjectOnWall(object),
      "width",
      value
    );

    object.width = value;
    object.len1 = object.pos;
    object.len2 = Math.floor(length - object.pos - object.width);
    endHandler();
  }

  useEffect(() => {
    const length = getParentLength(object, parent);

    object.len1 = object.pos;
    object.len2 = Math.floor(length - object.pos - object.width);

    setLen1(object.len1);
    setLen2(object.len2);
  }, [parent]);

  const handlerHeight = (value) => {
    if (value < 0) {
      return;
    }
    setHeight(value);

    localStorageParams.updateParam(
      getTypeObjectOnWall(object),
      "height",
      value
    );
    object.height = value;
    endHandler();
  };

  const handlerScale = (value) => {
    if (value <= 0) return;
    object.scale = value;
    setScale(value);
    endHandler();
  }

  const handlerDepth = (value) => {
    setDepth(value);
    object.depth = value;
    endHandler();
  };

  const handlerDepth3D = (value) => {
    if (value < 0) {
      return;
    }
    setDepthFor3D(value);
    object.depthFor3D = value;
    endHandler();
  };

  const handlerDepthIndent = (value) => {
    setDepthIndent(value);
    object.depthIndent = value;
    endHandler();
  };

  const handlerDepthLedge = (value) => {
    let depth = 0;
    if (parent?.isWall) {
      depth = parent.mainLink.depth + parent.mainLink.innerDepth - object.depth - value;
    }

    setDepthIndent(depth);
    object.depthIndent = depth;
    endHandler();
  };

  const handlerDepthIndentFor3D = (value) => {
    setDepthIndentFor3D(value);
    object.depthIndentFor3D = value;
    endHandler();
  };

  const handlerHeightFromFloor = (value) => {
    if (value < 0) {
      return;
    }
    setHeightFromFloor(value);

    localStorageParams.updateParam(
      getTypeObjectOnWall(object),
      "heightFromFloor",
      value
    );
    object.heightFromFloor = value;
    endHandler();
  };

  const handlerLrBuild = () => {
    if (object.lrBuild === "left") {
      object.lrBuild = "right";
    } else {
      object.lrBuild = "left";
    }
    setLrBuild(object.lrBuild);
    endHandler();
  };

  const handlerLen = (len1, len2) => {
    if (len2 === undefined) {
      len2 = maxLength - len1;
      if (len2 < 0) {
        len2 = 0;
      }
    }
    if (len1 === undefined) {
      len1 = maxLength - len2;
      if (len1 < 0) {
        len1 = 0;
      }
    }

    object.pos = len1;
    object.len1 = len1;
    object.len2 = len2;
    object.isCorner = false;

    setIsCorner(false)
    setPos(len1);
    setLen1(len1);
    setLen2(len2);

    endHandler();
  };

  const handlerLen1 = (value) => {
    if (value < 0) {
      return;
    }
    if (value > maxLength) {
      return;
    }
    let len2 = maxLength - value;
    handlerLen(undefined, len2);
  };

  const handlerLen2 = (value) => {
    if (value < 0) {
      return;
    }
    if (value > maxLength) {
      return;
    }
    let len1 = maxLength - value;
    handlerLen(len1, undefined);
  };

  const toggleInside = () => {
    object.inside = !inside;
    setInside(!inside);
    sendRedrawSimpleEvent(document.querySelector("#plan"));

    plan.setActionUndo({ type: "plan" });
  };

  const [isConnected, setIsConnected] = useState(object.isConnected);

  const toggleConnected = () => {
    object.isConnected = !isConnected;

    if (object.connected) {
      object.connected.object.isHidden = object.isConnected;
      object.connected.object.isConnected = false;
    }

    setIsConnected(!isConnected);
    sendRedrawSimpleEvent(document.querySelector("#plan"));

    plan.setActionUndo({ type: "plan" });
  };


  const toggleLeft = () => {
    object.left = !left;
    setLeft(!left);
    sendRedrawSimpleEvent(document.querySelector("#plan"));

    plan.setActionUndo({ type: "plan" });
  };

  const toggleCorner = () => {
    object.isCorner = !isCorner;

    object.cornerWall = plan.bWalls.find((wall) => {
      if (!object.isCorner) return false;
      if (wall === parent) return false;

      if (len1 === 0) {
        return (wall.mainLink.a.x === parent.mainLink.a.x && wall.mainLink.a.y === parent.mainLink.a.y)
            || (wall.mainLink.b.x === parent.mainLink.a.x && wall.mainLink.b.y === parent.mainLink.a.y);
      } else if (len2 === 0) {
        return (wall.mainLink.a.x === parent.mainLink.b.x && wall.mainLink.a.y === parent.mainLink.b.y)
            || (wall.mainLink.b.x === parent.mainLink.b.x && wall.mainLink.b.y === parent.mainLink.b.y)
      }

      return false;
    })

    setIsCorner(!isCorner);
    sendRedrawSimpleEvent(document.querySelector("#plan"));

    plan.setActionUndo({ type: "plan" });
  };

  const toggleHoleType = () => {
    object.isCircleHole = !isCircleHole;
    setIsCircleHole(!isCircleHole);
    sendRedrawSimpleEvent(document.querySelector("#plan"));

    plan.setActionUndo({ type: "plan" });
  }

  const setHoleVariant = (val) => {
    const objectParams = localStorageParams.getParams(val);
    const { width, height, heightFromFloor } = objectParams;

    const wallLength = getParentLength(object, parent);
    const wallHeight = parent?.mainLink?.height || 0;

    object.isHole = false;
    object.isDoor = false;
    object.isWindow = false;
    object.isCircleHole = false;
    setIsHole(false);
    setIsDoor(false);
    setIsWindow(false);
    if (val === "isHole") {
      object.isHole = true;
      setIsHole(true);
      if (width) {
        if (width <= wallLength) {
          object.width = width;
        } else {
          handlerLen1(0);
          object.width = wallLength;
        }
      }
      if (height) {
        object.height = (height <= wallHeight) ? height : wallHeight;
      }
      if (heightFromFloor)
        object.heightFromFloor = heightFromFloor;
    }
    if (val === "isDoor") {
      object.isDoor = true;
      setIsDoor(true);
      setDepthIndent(0);
      if (parent?.isWall) object.depth = parent.mainLink.depth + parent.mainLink.innerDepth;
      object.depthIndent = 0;
      setHeightFromFloor(50);
      object.heightFromFloor = 50;
      if (width) {
        if (width <= wallLength) {
          object.width = width;
        } else {
          handlerLen1(0);
          object.width = wallLength;
        }
      }
      if (height) {
        object.height = (height <= wallHeight) ? height : wallHeight;
      }

      let depth = 0;
      if (parent?.isWall) {
        depth = parent.mainLink.depth + parent.mainLink.innerDepth;
      }

      setDepth(depth);
      object.depth = depth;
    }
    if (val === "isWindow") {
      object.depth = 50;
      object.isWindow = true;
      setIsWindow(true);
      if (width) {
        if (width <= wallLength) {
          object.width = width;
        } else {
          handlerLen1(0);
          object.width = wallLength;
        }
      }
      if (height) {
        object.height = (height <= wallHeight) ? height : wallHeight;
      }
      if (heightFromFloor)
        object.heightFromFloor = heightFromFloor;
    }
  };

  const setObjectOnWall = (val) => {
    object.isElectricSocket = false;
    object.isSwitch = false;
    object.isHeatingBattery = false;
    object.isElectricPanel = false;
    object.isRedCube = false;
    object.isCylinder = false;
    object.outletElectricalWire = false;

    const objectParams = localStorageParams.getParams(val);
    const { width, height, heightFromFloor } = objectParams;

    if (val === "isElectricSocket") {
      object.scale = 2;
      setSateObjectOnWall({
        isElectricSocket: (object.isElectricSocket = true),
        isSwitch: false,
        isHeatingBattery: false,
        isElectricPanel: false,
        isRedCube: false,
        isCylinder: false,
        outletElectricalWire: false,
      });
      handlerHeightFromFloor(heightFromFloor || 300);
      handlerWidth(width || 65);
      handlerHeight(height || 65);
      handlerDepth3D(10);
      handlerDepthIndent(0);
      handlerDepthIndentFor3D(0);
    }

    if (val === "isSwitch") {
      object.scale = 2;
      setSateObjectOnWall({
        isElectricSocket: false,
        isSwitch: (object.isSwitch = true),
        isHeatingBattery: false,
        isElectricPanel: false,
        isRedCube: false,
        isCylinder: false,
        outletElectricalWire: false,
      });
      handlerHeightFromFloor(heightFromFloor || 500);
      handlerWidth(width || 65);
      handlerHeight(height || 65);
      handlerDepth3D(10);
      handlerDepthIndent(0);
      handlerDepthIndentFor3D(0);
    }

    if (val === "outletElectricalWire") {
      object.scale = 2;
      setSateObjectOnWall({
        isElectricSocket: false,
        outletElectricalWire: (object.outletElectricalWire = true),
        isHeatingBattery: false,
        isElectricPanel: false,
        isRedCube: false,
        isCylinder: false,
        isSwitch: false,
      });
      handlerHeightFromFloor(heightFromFloor || 500);
      handlerWidth(width || 65);
      handlerHeight(height || 65);
      handlerDepth3D(10);
      handlerDepthIndent(0);
      handlerDepthIndentFor3D(0);
    }

    if (val === "isHeatingBattery") {
      object.scale = 1;
      setSateObjectOnWall({
        isElectricSocket: false,
        isSwitch: false,
        isHeatingBattery: (object.isHeatingBattery = true),
        isElectricPanel: false,
        isRedCube: false,
        isCylinder: false,
        outletElectricalWire: false,
      });
      handlerHeightFromFloor(heightFromFloor || 0);
      handlerWidth(width || 500);
      handlerHeight(height || 500);
      handlerDepth3D(100);
      handlerDepthIndent(0);
      handlerDepthIndentFor3D(0);
    }

    if (val === "isElectricPanel") {
      object.scale = 1;
      setSateObjectOnWall({
        isElectricSocket: false,
        isSwitch: false,
        isHeatingBattery: false,
        isElectricPanel: (object.isElectricPanel = true),
        isRedCube: false,
        isCylinder: false,
        outletElectricalWire: false,
      });
      handlerHeightFromFloor(heightFromFloor || 1000);
      handlerWidth(width || 200);
      handlerHeight(height || 200);
      handlerDepth3D(100);
      handlerDepthIndent(0);
      handlerDepthIndentFor3D(0);
      setRgb({ r: 255, g: 0, b: 0, a: 1 });
    }

    if (val === "isRedCube") {
      object.scale = 1;
      setSateObjectOnWall({
        isElectricSocket: false,
        isSwitch: false,
        isHeatingBattery: false,
        isElectricPanel: false,
        isRedCube: (object.isRedCube = true),
        isCylinder: false,
        outletElectricalWire: false,
      });
      handlerHeightFromFloor(heightFromFloor || 0);
      handlerWidth(width || 500);
      handlerHeight(height || 500);
      handlerDepth3D(500);
      handlerDepthIndent(0);
      handlerDepthIndentFor3D(0);
      setRgb({ r: 255, g: 0, b: 0, a: 1 });
    }

    if (val === "isCylinder") {
      object.scale = 2;
      setSateObjectOnWall({
        isElectricSocket: false,
        isSwitch: false,
        isHeatingBattery: false,
        isElectricPanel: false,
        isRedCube: false,
        isCylinder: (object.isCylinder = true),
        outletElectricalWire: false,
      });
      handlerHeightFromFloor(heightFromFloor || 0);
      handlerWidth(width || 500);
      handlerHeight(height || 500);
      handlerDepth3D(0);
      handlerDepth(0);
      handlerDepthIndent(0);
      handlerDepthIndentFor3D(0);
      setRgb({ r: 255, g: 0, b: 0, a: 1 });
    }
  };

  const setObjectVariant = (val) => {
    const prevVal = getTypeObjectOnWall(object);
    isAperture && setHoleVariant(val);
    isObjectOnWall && setObjectOnWall(val);
    parent.setOrdinalNumber(prevVal);
    parent.setOrdinalNumber(val);
    setStatusDistanceButton({ property: "width", action: handlerWidth });
    endHandler();
  };

  const remove = () => {
    plan.setActionUndo({ type: 'plan' });
    parent.removeObject(parent?.isWall ? objIndex : object);
    sendUnselectEvent(document.querySelector("#plan"));
    sendRedrawSimpleEvent(document.querySelector("#plan"));
    endHandler();
  };

  useShortcuts({
    onDelete: remove,
  });

  const close = () => {
    // sendUnselectEvent(document.querySelector('#plan'));
    dispatch(projectState.setModal(""));
    if (planeEdit) {
      sendSelectObjectEvent(document.querySelector(canvasSelector), parent, parent);
    }
  };

  const handlerColor = (rgb) => {
    object.rgb = rgb;
    setRgb(rgb);
    endHandler();
  };

  const handlerType = (type) => {
    object.count = Number(type.slice(-1));
    object.type = type.substring(0, type.length - 1);
    setType(type);
    endHandler();
  };

  const setFullWidth = () => {
    handlerLen1(0);

    const length = getParentLength(object, parent);

    handlerWidth(length);
    handlerHeightFromFloor(0);

    if (parent?.isWall) {
      handlerHeight(parent.mainLink.height);
    }
  };

  const setStatusDistanceButton = ({ property, action }) => {
    const newDistanceButton = {
      property: {},
      action: {},
    };
    for (let key in distanceButton.property) {
      newDistanceButton.property[key] = property === key ? true : false;
    }
    newDistanceButton.action = action;
    setDistanceButton(newDistanceButton);
  };

  useEffect(() => {
    setPos(object.pos);
    setType(object.type);
    setLen1(object.len1);
    setLen2(object.len2);

    setIsConnected(object.isConnected);

    setIsHole(object.isHole);
    setIsDoor(object.isDoor);
    setIsWindow(object.isWindow);
    setScale(object.scale);
    setIsCircleHole(object.isCircleHole);
    setSateObjectOnWall({
      isElectricSocket: object.isElectricSocket,
      isSwitch: object.isSwitch,
      isHeatingBattery: object.isHeatingBattery,
      isElectricPanel: object.isElectricPanel,
      isRedCube: object.isRedCube,
      isCylinder: object.isCylinder,
      outletElectricalWire: object.outletElectricalWire,
    });
  }, [
    object.pos,
    object.type,
    object.len1,
    object.len2,
    object.depth,
    object.depthIndent,
    object.inside,
    object.left,
    object.isHole,
    object.isDoor,
    object.isWindow,
    object.scale,
    object.isCircleHole,
    object.isConnected,
  ]);

  useEffect(() => {
    if (bluetoothStatus !== "connected") {
      return;
    }
    if (Object.keys(distance.setCallBack)) {
      distance.setCallBack(distanceButton.action);
    } else {
      distance.start(distanceButton.action);
    }
    return () => distance.setCallBack({});
  }, [bluetoothStatus, distanceButton]);

  const maxWindowDepth = parent?.isWall ? (parent.mainLink.depth + parent.mainLink.innerDepth - object.depthIndent) : 0;
  const maxDepthIndent = parent?.isWall ? (parent.mainLink.depth + parent.mainLink.innerDepth - object.depth) : 0;
  const depthLedge = parent?.isWall ? (parent.mainLink.depth + parent.mainLink.innerDepth - object.depthIndent - object.depth) : 0;
  const maxDepthLedge = parent?.isWall ? (parent.mainLink.depth + parent.mainLink.innerDepth - object.depth) : 0;

  const apertureInfo = (
    <ModalWrapper
      isSideMenu={true}
      title={title}
      onDelete={() => remove()}
      onClose={() => close()}
      planeEdit={planeEdit}
    >
      <div className="modal-body">
        <div className={'options-title-wrap'}>
          <div className={'options-title'}>Проем/ниша</div>
          <div className={'options-title__option'}>
            <div
                className={
                  object.isHole
                      ? 'oval-checkbox oval-checkbox_active'
                      : 'oval-checkbox'
                }
                onClick={() => setObjectVariant('isHole')}
            >
              &nbsp;
            </div>
          </div>
        </div>
        <div className={'options-title-wrap'}>
          <div className={'options-title'}>Дверь</div>
          <div className={'options-title__option'}>
            <div
                className={
                  object.isDoor
                      ? 'oval-checkbox oval-checkbox_active'
                      : 'oval-checkbox'
                }
                onClick={() => setObjectVariant('isDoor')}
            >
              &nbsp;
            </div>
          </div>
        </div>
        <div className={'options-title-wrap'}>
          <div className={'options-title'}>Окно</div>
          <div className={'options-title__option'}>
            <div
                className={
                  object.isWindow
                      ? 'oval-checkbox oval-checkbox_active'
                      : 'oval-checkbox'
                }
                onClick={() => setObjectVariant('isWindow')}
            >
              &nbsp;
            </div>
          </div>
        </div>

        <div className="options-title">{objName}</div>
        <div className="size-block propertyObj">
          <div className="btn btn-icon" onClick={setFullWidth}>
            Расширить во всю стену
          </div>
        </div>
        {object.isHole && (
            <div className={'options-title-wrap'}>
              <div className={'options-title'}>Круглый проем</div>
              <div className={'options-title__option'}>
                <div
                    className={
                      object?.isCircleHole
                          ? 'oval-checkbox oval-checkbox_active'
                          : 'oval-checkbox'
                    }
                    onClick={toggleHoleType}
                >
                  &nbsp;
                </div>
              </div>
            </div>
        )}
        {(object?.isConnected === true || object?.isConnected === false) &&
            <div className={'options-title-wrap'}>
              <div className={'options-title'}>Отображать объединенный проем</div>
              <div className={'options-title__option'}>
                <div
                    className={
                      object.isConnected
                          ? 'oval-checkbox oval-checkbox_active'
                          : 'oval-checkbox'
                    }
                    onClick={toggleConnected}
                >
                  &nbsp;
                </div>
              </div>
            </div>}
        <div className="size-block">
          <h2>{object?.isCircleHole ? 'Диаметр' : 'Ширина'}</h2>
          {bluetoothStatus === 'connected' && (
              <DistanceButton
                  property={'width'}
                  action={handlerWidth}
                  distanceButtonProperty={distanceButton.property}
                  setStatusDistanceButtonState={setStatusDistanceButton}
              />
          )}
          <SizeBlock
              value={object.width}
              onChange={handlerWidth}
              min={5}
              max={false}
              step={5}
              isLocked={false}
          />
        </div>
        {!object?.isCircleHole &&
            <div className="size-block">
              <h2>Высота</h2>
              {bluetoothStatus === 'connected' && (
                  <DistanceButton
                      property={'height'}
                      action={handlerHeight}
                      distanceButtonProperty={distanceButton.property}
                      setStatusDistanceButtonState={setStatusDistanceButton}
                  />
              )}
              <SizeBlock
                  value={object.height}
                  onChange={handlerHeight}
                  min={5}
                  max={false}
                  step={5}
                  isLocked={false}
              />
            </div>
        }
        {(object.isWindow || object.isHole || object.isDoor) && (
            <div className="size-block">
              <h2>{object.isWindow ? 'Толщина стеклопакета' : 'Глубина'}</h2>
              {bluetoothStatus === 'connected' && (
                  <DistanceButton
                      property={'depth'}
                      action={handlerWidth}
                      distanceButtonProperty={distanceButton.property}
                      setStatusDistanceButtonState={setStatusDistanceButton}
                  />
              )}
              <SizeBlock
                  value={object.depth}
                  onChange={handlerDepth}
                  min={1}
                  max={maxWindowDepth}
                  step={5}
                  isLocked={false}
              />
            </div>
        )}
        {(object.isWindow || object.isHole || object.isDoor) && (
            <div className="size-block">
              <h2>Отступ от пола</h2>
              {bluetoothStatus === 'connected' && (
                  <DistanceButton
                      property={'heightFromFloor'}
                      action={handlerHeightFromFloor}
                      distanceButtonProperty={distanceButton.property}
                      setStatusDistanceButtonState={setStatusDistanceButton}
                  />
              )}
              <SizeBlock
                  value={object.heightFromFloor}
                  onChange={handlerHeightFromFloor}
                  min={0}
                  max={false}
                  step={5}
                  isLocked={false}
              />
            </div>
        )}
        {(object.isWindow || object.isHole || object.isDoor) && (
            <div className="size-block">
              <h2 className={(object.isWindow || object.isDoor) ? 'title-os' : undefined}>
                {(object.isWindow || object.isDoor) ? 'Откос внутри' : 'Отступ внутри стены'}
              </h2>
              {bluetoothStatus === 'connected' && (
                  <DistanceButton
                      property={'depthIndent'}
                      action={handlerDepthIndent}
                      distanceButtonProperty={distanceButton.property}
                      setStatusDistanceButtonState={setStatusDistanceButton}
                  />
              )}
              <SizeBlock
                  value={object.depthIndent}
                  onChange={handlerDepthIndent}
                  min={0}
                  max={maxDepthIndent}
                  step={5}
                  isLocked={false}
              />
            </div>
        )}
        {(object.isWindow || object.isDoor) && (
            <div className="size-block">
              <h2 className={'title-is'}>{'Откос снаружи'}</h2>
              {bluetoothStatus === 'connected' && (
                  <DistanceButton
                      property={'depthLedge'}
                      action={handlerDepthLedge}
                      distanceButtonProperty={distanceButton.property}
                      setStatusDistanceButtonState={setStatusDistanceButton}
                  />
              )}
              <SizeBlock
                  value={depthLedge}
                  onChange={handlerDepthLedge}
                  min={0}
                  max={maxDepthLedge}
                  step={5}
                  isLocked={false}
              />
            </div>
        )}
        <div className="size-block">
          <h2 className={'title-ls'}>Отступ вдоль стены 1</h2>
          {bluetoothStatus === 'connected' && (
              <DistanceButton
                  property={'Len1'}
                  action={handlerLen1}
                  distanceButtonProperty={distanceButton.property}
                  setStatusDistanceButtonState={setStatusDistanceButton}
              />
          )}
          <SizeBlock
              value={object.len1}
              onChange={handlerLen1}
              min={0}
              max={maxLength}
              step={5}
              isLocked={false}
          />
        </div>
        <div className="size-block">
          <h2 className={'title-rs'}>Отступ вдоль стены 2</h2>
          {bluetoothStatus === 'connected' && (
              <DistanceButton
                  property={'Len2'}
                  action={handlerLen2}
                  distanceButtonProperty={distanceButton.property}
                  setStatusDistanceButtonState={setStatusDistanceButton}
              />
          )}
          <SizeBlock
              value={object.len2}
              onChange={handlerLen2}
              min={0}
              max={maxLength}
              step={5}
              isLocked={false}
          />
        </div>
        {object.isWindow && (object.len1 === 0 || object.len2 === 0) &&
            <div className={'options-title-wrap'}>
              <div className={'options-title'}>Угловое окно</div>
              <div className={'options-title__option'}>
                <div
                    className={
                      object?.isCorner
                          ? 'oval-checkbox oval-checkbox_active'
                          : 'oval-checkbox'
                    }
                    onClick={toggleCorner}
                >
                  &nbsp;
                </div>
              </div>
            </div>}
        {object.isDoor && (
            <div className={'options-title-wrap'}>
              <div className={'options-title'}>Открывание наружу/внутрь</div>
              <div className={'options-title__option'}>
                <div
                    className={
                      object.inside
                          ? 'oval-checkbox oval-checkbox_active'
                          : 'oval-checkbox'
                    }
                    onClick={toggleInside}
                >
                  &nbsp;
                </div>
              </div>
            </div>
        )}
        {object.isDoor && (
            <div className={'options-title-wrap'}>
              <div className={'options-title'}>Открывание левое/правое</div>
              <div className={'options-title__option'}>
                <div
                    className={
                      object.left
                          ? 'oval-checkbox oval-checkbox_active'
                          : 'oval-checkbox'
                    }
                    onClick={toggleLeft}
                >
                  &nbsp;
                </div>
              </div>
            </div>
        )}

        <ObjectInfoBlock obj={object}/>
        <EstimateInfo object={object}/>
      </div>
    </ModalWrapper>
  );

  const objectOnWallInfo = (
      <ModalWrapper
          isSideMenu={true}
          title={title}
          onDelete={() => remove()}
          onClose={() => close()}
          planeEdit={planeEdit}
      >
        <div className="modal-body">
          <div className={'options-title-wrap'}>
            <div className={'options-title'}>Розетки</div>
            <div className={'options-title__option'}>
              <div
                  className={
                    object.isElectricSocket
                        ? 'oval-checkbox oval-checkbox_active'
                  : "oval-checkbox"
              }
              onClick={() => setObjectVariant("isElectricSocket")}
            >
              &nbsp;
            </div>
          </div>
          {object.isElectricSocket && (
            <Select
              options={optionsObjectsCount}
              onChange={(option) => handlerType(option.value)}
              value={optionsObjectsCount.find(
                (option) =>
                  option.value === `${type}${object.count}`
              )}
            />
          )}
        </div>
        <div className={"options-title-wrap"}>
          <div className={"options-title"}>Выключатель</div>
          <div className={"options-title__option"}>
            <div
              className={
                object.isSwitch
                  ? "oval-checkbox oval-checkbox_active"
                  : "oval-checkbox"
              }
              onClick={() => setObjectVariant("isSwitch")}
            >
              &nbsp;
            </div>
          </div>
          {object.isSwitch && (
            <Select
              options={optionsObjectsCount}
              onChange={(option) => handlerType(option.value)}
              value={optionsObjectsCount.find(
                (option) =>
                  option.value === `${type}${object.count}`
              )}
            />
          )}
        </div>
        <div className={"options-title-wrap"}>
          <div className={"options-title"}>Вывод электропровода</div>
          <div className={"options-title__option"}>
            <div
              className={
                object.outletElectricalWire
                  ? "oval-checkbox oval-checkbox_active"
                  : "oval-checkbox"
              }
              onClick={() => setObjectVariant("outletElectricalWire")}
            >
              &nbsp;
            </div>
          </div>
          {object.outletElectricalWire && (
            <Select
              options={optionsObjectsCount}
              onChange={(option) => handlerType(option.value)}
              value={optionsObjectsCount.find(
                (option) => option.value === `${type}${object.count}`
              )}
            />
          )}
        </div>
        <div className={"options-title-wrap"}>
          <div className={"options-title"}>Батарея отопления</div>
          <div className={"options-title__option"}>
            <div
              className={
                object.isHeatingBattery
                  ? "oval-checkbox oval-checkbox_active"
                  : "oval-checkbox"
              }
              onClick={() => setObjectVariant("isHeatingBattery")}
            >
              &nbsp;
            </div>
          </div>
        </div>
        <div className={"options-title-wrap"}>
          <div className={"options-title"}>Электрощит</div>
          <div className={"options-title__option"}>
            <div
                className={
                  object.isElectricPanel
                      ? "oval-checkbox oval-checkbox_active"
                      : "oval-checkbox"
                }
                onClick={() => setObjectVariant("isElectricPanel")}
            >
              &nbsp;
            </div>
          </div>
        </div>
        <div className={"options-title-wrap"}>
          <div className={"options-title"}>Куб</div>
          <div className={"options-title__option"}>
            <div
              className={
                object.isRedCube
                  ? "oval-checkbox oval-checkbox_active"
                  : "oval-checkbox"
              }
              onClick={() => setObjectVariant("isRedCube")}
            >
              &nbsp;
            </div>
          </div>
        </div>
        <div className={"options-title-wrap"}>
          <div className={"options-title"}>Цилиндр</div>
          <div className={"options-title__option"}>
            <div
              className={
                object.isCylinder
                  ? "oval-checkbox oval-checkbox_active"
                  : "oval-checkbox"
              }
              onClick={() => setObjectVariant("isCylinder")}
            >
              &nbsp;
            </div>
          </div>
        </div>

        <div className="options-title">{objName}</div>
        {parent?.isWall &&
          <div className="size-block propertyObj">
            <div className="btn btn-icon btn-onestyle" onClick={handlerLrBuild}>
              {object.lrBuild === "left" && (
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512"
                >
                  <path d="M256,0c-8.284,0-15,6.716-15,15v70c0,8.284,6.716,15,15,15s15-6.716,15-15V15C271,6.716,264.284,0,256,0z" />
                  <path d="M256,137c-8.284,0-15,6.716-15,15v70c0,8.284,6.716,15,15,15s15-6.716,15-15v-70C271,143.716,264.284,137,256,137z" />
                  <path d="M256,275c-8.284,0-15,6.716-15,15v70c0,8.284,6.716,15,15,15s15-6.716,15-15v-70C271,281.716,264.284,275,256,275z" />
                  <path d="M256,412c-8.284,0-15,6.716-15,15v70c0,8.284,6.716,15,15,15s15-6.716,15-15v-70C271,418.716,264.284,412,256,412z" />
                  <path
                    d="M179.402,103.503l-120-36.842c-4.549-1.398-9.49-0.553-13.317,2.276C42.258,71.765,40,76.241,40,81v350
                  c0,4.759,2.258,9.235,6.085,12.063c3.841,2.839,8.787,3.668,13.317,2.275l120-36.842c6.299-1.933,10.598-7.751,10.598-14.339
                  V117.842C190,111.254,185.7,105.437,179.402,103.503z"
                  />
                  <path
                    d="M465.915,68.937c-3.827-2.828-8.769-3.674-13.317-2.276l-120,36.842c-6.299,1.933-10.598,7.751-10.598,14.339v276.316
                          c0,6.588,4.3,12.405,10.598,14.339l120,36.842c4.566,1.402,9.505,0.541,13.317-2.276C469.742,440.235,472,435.759,472,431V81
                          C472,76.241,469.742,71.765,465.915,68.937z M442,410.704l-90-27.632V128.928l90-27.632V410.704z"
                  />
                </svg>
              )}
              {object.lrBuild === "right" && (
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512"
                >
                  <path d="M256,0c-8.284,0-15,6.716-15,15v70c0,8.284,6.716,15,15,15s15-6.716,15-15V15C271,6.716,264.284,0,256,0z" />
                  <path d="M256,137c-8.284,0-15,6.716-15,15v70c0,8.284,6.716,15,15,15s15-6.716,15-15v-70C271,143.716,264.284,137,256,137z" />
                  <path d="M256,275c-8.284,0-15,6.716-15,15v70c0,8.284,6.716,15,15,15s15-6.716,15-15v-70C271,281.716,264.284,275,256,275z" />
                  <path d="M256,412c-8.284,0-15,6.716-15,15v70c0,8.284,6.716,15,15,15s15-6.716,15-15v-70C271,418.716,264.284,412,256,412z" />
                  <path
                    d="M179.402,103.503l-120-36.842c-4.549-1.398-9.491-0.552-13.317,2.276C42.258,71.765,40,76.241,40,81v350
                              c0,4.759,2.258,9.235,6.085,12.063c3.841,2.839,8.787,3.668,13.317,2.275l120-36.842c6.299-1.933,10.598-7.751,10.598-14.339
                              V117.842C190,111.254,185.7,105.437,179.402,103.503z M160,383.071l-90,27.632V101.296l90,27.632V383.071z"
                  />
                  <path
                    d="M465.915,68.937c-3.826-2.828-8.77-3.673-13.317-2.276l-120,36.842c-6.299,1.933-10.598,7.751-10.598,14.339v276.316
                      c0,6.588,4.3,12.405,10.598,14.339l120,36.842c4.566,1.402,9.505,0.541,13.317-2.276C469.742,440.235,472,435.759,472,431V81
                      C472,76.241,469.742,71.765,465.915,68.937z"
                  />
                </svg>
              )}
              Построение
            </div>
          </div>}
        <div className="size-block">
          <h2>{object?.isCylinder ? "Диаметр" : "Ширина"}</h2>
          {bluetoothStatus === "connected" && (
            <DistanceButton
              property={"width"}
              action={handlerWidth}
              distanceButtonProperty={distanceButton.property}
              setStatusDistanceButtonState={setStatusDistanceButton}
            />
          )}
          <SizeBlock
            value={object.width}
            onChange={handlerWidth}
            min={10}
            max={false}
            step={5}
            isLocked={false}
          />
        </div>
        <div className="size-block">
          <h2>Высота</h2>
          {bluetoothStatus === "connected" && (
            <DistanceButton
              property={"height"}
              action={handlerHeight}
              distanceButtonProperty={distanceButton.property}
              setStatusDistanceButtonState={setStatusDistanceButton}
            />
          )}
          <SizeBlock
            value={object.height}
            onChange={handlerHeight}
            min={10}
            max={false}
            step={5}
            isLocked={false}
          />
        </div>
        {!object?.isCylinder && (
          <div className="size-block">
            <h2>Толщина</h2>
            {bluetoothStatus === "connected" && (
              <DistanceButton
                property={"depth3D"}
                action={handlerDepth3D}
                distanceButtonProperty={distanceButton.property}
                setStatusDistanceButtonState={setStatusDistanceButton}
              />
            )}
            <SizeBlock
              value={object.depthFor3D}
              onChange={handlerDepth3D}
              min={5}
              max={false}
              step={5}
              isLocked={false}
            />
          </div>
        )}
        <div className="size-block">
          <h2>Отступ от пола</h2>
          {bluetoothStatus === "connected" && (
            <DistanceButton
              property={"heightFromFloor"}
              action={handlerHeightFromFloor}
              distanceButtonProperty={distanceButton.property}
              setStatusDistanceButtonState={setStatusDistanceButton}
            />
          )}
          <SizeBlock
            value={object.heightFromFloor}
            onChange={handlerHeightFromFloor}
            min={0}
            max={false}
            step={5}
            isLocked={false}
          />
        </div>
        {(object.isHeatingBattery || object.isElectricPanel || object.isRedCube || object.isCylinder) && (
          <div className="size-block">
            <h2>Отступ от стены</h2>
            {bluetoothStatus === "connected" && (
              <DistanceButton
                property={"depthIndentFor3D"}
                action={handlerDepthIndentFor3D}
                distanceButtonProperty={distanceButton.property}
                setStatusDistanceButtonState={setStatusDistanceButton}
              />
            )}
            <SizeBlock
              value={object.depthIndentFor3D}
              onChange={handlerDepthIndentFor3D}
              max={false}
              step={5}
              isLocked={false}
            />
          </div>
        )}
        <div className="size-block">
          <h2 className={"title-ls"}>Отступ вдоль стены 1</h2>
          {bluetoothStatus === "connected" && (
            <DistanceButton
              property={"Len1"}
              action={handlerLen1}
              distanceButtonProperty={distanceButton.property}
              setStatusDistanceButtonState={setStatusDistanceButton}
            />
          )}
          <SizeBlock
            value={object.len1}
            onChange={handlerLen1}
            min={0}
            max={maxLength}
            step={5}
            isLocked={false}
          />
        </div>
        <div className="size-block">
          <h2 className={"title-rs"}>Отступ вдоль стены 2</h2>
          {bluetoothStatus === "connected" && (
            <DistanceButton
              property={"Len2"}
              action={handlerLen2}
              distanceButtonProperty={distanceButton.property}
              setStatusDistanceButtonState={setStatusDistanceButton}
            />
          )}
          <SizeBlock
            value={object.len2}
            onChange={handlerLen2}
            min={0}
            max={maxLength}
            step={5}
            isLocked={false}
          />
        </div>
        <div className="size-block">
          <h2>Масштабирование</h2>
          <SizeBlock
              value={object.scale}
              onChange={handlerScale}
              min={1}
              max={10}
              step={1}
              isLocked={false}
          />
        </div>
        {(object.isRedCube || object.isCylinder) && (
          <div className="size-block">
            <ColorRBG
              _setRgbColor={handlerColor}
              colorDef={object.rgb}
            />
          </div>
        )}

        <ObjectInfoBlock obj={object} />
        <EstimateInfo object={object} />
      </div>
    </ModalWrapper>
  );

  if (isAperture) {
    return apertureInfo;
  }
  if (isObjectOnWall) {
    return objectOnWallInfo;
  }
};

export default WallObjectInfo;
