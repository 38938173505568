import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionsState as projectState } from '../Redux/project';
import Preloader from '../Components/Preloader';

export const History = () => {
    const dispatch = useDispatch();
    const versions = useSelector((state) => state.project.versions);

    const openVersion = (id)=>{
        dispatch(projectState.addPreloader());
        window.location = window.location.href.replace('/history', '') + '&vid=' + id;
        dispatch(projectState.decPreloader());
    }

    return (
        <div style={{ padding: '1rem 2rem' }}>
            <h2 style={{ margin: 0, marginBottom: '1rem' }}>Версии проекта</h2>
            {versions.map((version, index) => {
                return (
                    <div
                        key={index}
                        className="versions-el"
                        onClick={() => openVersion(version.ID)}
                    >
                        <div>{version.NAME} <span>{version.AUTOSAVE ? 'Автосохранение' : 'Сохранение'}</span></div>
                        <div>
                            {version.CRM_USER}
                            <span>{version.CRM_RIGHTS}</span>
                        </div>
                    </div>
                )
            })}
            <Preloader/>
        </div>
    );
}
